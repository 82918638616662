<template>
  <header
    class="event-pass-summary-header flexing w-full mb-2"
    :class="{ 'is-premium': $route.meta.isPremiumRoute }"
    :data-event-theme="namespace"
  >
    <p class="flexing text-34 text-texts-standard-default font-bold italic uppercase">
      {{ $te('eventPassInfo') }}
    </p>
  </header>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'EventPass3SummaryHeader',
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='frozen'] {
  --events-passes-sub-header-bg: url('#{$path-events}frozen-championship/passes/event-pass-sub-header-bg.webp');
}

.event-pass-summary-header {
  height: 4rem;
  @include background(var(--events-passes-sub-header-bg), cover);

  &.is-premium {
    height: 5.125rem;
    background-image: none;
  }
}
</style>
