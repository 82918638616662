<template>
  <div class="tasks-career app-page-wrapper absolute">
    <menu-component
      menu-type="tasks"
      :title="$t('career.quests')"
      :sub-menu-active="true"
      :sub-menu="subMenu"
    />
    <section class="w-full h-full safe-area">
      <main v-if="dataReady" class="flexing">
        <side-menu-box
          :disciplines-menu-data="disciplinesMenuData"
          @click-on-discipline="goToCareerBranch"
        />
        <section class="rightside" :class="{ 'rightside-unlocked': !isDisciplineLocked }">
          <career-box
            v-if="getActiveQuest != null && !isDisciplineLocked"
            :quest="getActiveQuest"
            :discipline-id="disciplineId"
            :unlock-item="getUnlockItem"
            @load-detail="loadCareerDetail(careerId)"
          />

          <career-branch-locked
            v-if="isDisciplineLocked"
            :discipline-id="disciplineId"
            :unlock-level="levelToUnlock"
            :temporary-lock="temporaryLock"
            :completed-quests="completedQuests"
          />
        </section>
      </main>

      <component-loading :height="'72%'" :is-loading="!dataReady" />
    </section>
  </div>
</template>

<script lang="ts">
import CareerBox from '@/components/Career/CareerBox.vue'
import CareerBranchLocked from '@/components/Career/CareerBranchLocked.vue'
import SideMenuBox from '@/components/Tasks/SideMenuBox.vue'
import { MECHANIC_CAREER, MECHANIC_DAILY_QUESTS } from '@/globalVariables'
import { EventMechanics } from '@/interfaces/events/EventInfo'
import { useCareerDetailStore } from '@/store/pinia/career/careerDetail'
import { useListOfUnlocksStore } from '@/store/pinia/career/listOfUnlocks'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

import type { CareerUnlockItem } from '@/interfaces/CareerUnlockItem'
import type { MenuObject } from '@/interfaces/MenuObject'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'

interface ComponentData {
  MECHANIC_DAILY_QUESTS: typeof MECHANIC_DAILY_QUESTS
  dataReady: boolean
  disciplineId: number
  questId: number
  careerId: string
  isDisciplineLocked: boolean
  levelToUnlock: number
  temporaryLock: boolean
  completedQuests: number
}

export default defineComponent({
  name: 'TasksCareer',
  components: {
    CareerBox,
    SideMenuBox,
    CareerBranchLocked,
  },
  data(): ComponentData {
    return {
      MECHANIC_DAILY_QUESTS,
      dataReady: false,
      disciplineId: 0,
      questId: 0,
      careerId: '',
      isDisciplineLocked: false,
      levelToUnlock: 0,
      temporaryLock: false,
      completedQuests: 0,
    }
  },
  computed: {
    ...mapState(useCareerDetailStore, {
      careerDetail: 'getCareerDetail',
      getActiveQuest: 'getActiveQuest',
      getQuestById: 'getQuestById',
    }),
    ...mapState(useListOfUnlocksStore, {
      listOfUnlocks: 'getListOfUnlocks',
    }),
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    ...mapState(useResponseTaskStore, ['hasMechanic', 'getMechanicUnlock']),
    ...mapState(useEventInfoStore, {
      eventType: 'getEventType',
      eventMechanics: 'getEventMechanics',
      eventTeaserActive: 'getEventTeaserActive',
      eventActive: 'getEventActive',
      eventCollectionActive: 'getEventCollectionActive',
      eventLocked: 'getEventLocked',
      eventMechanicByType: 'getEventMechanicByType',
    }),
    getUnlockItem(): CareerUnlockItem {
      const quest = this.getQuestById(Number(this.$route.params?.task)) ?? this.getActiveQuest
      return this.listOfUnlocks.find(
        (item: CareerUnlockItem): boolean => item.questOrder === quest.parameters?.order,
      )
    },
    subMenu(): Record<number, MenuObject> {
      return {
        1: {
          text: this.$t('career.dailyQuests'),
          route: this.$getWebView('DailyTask'),
          isDisabled: !this.hasMechanic(MECHANIC_DAILY_QUESTS),
          disabledText: this.$t('rankingsGroup.completeQuestsToUnlock'),
        },
        2: {
          text: this.$t('career.career'),
          route: this.$getWebView('TasksCareer'),
          current: this.$getWebView('TasksCareer'),
        },
        3: {
          text: this.$t('event.eventsTasks'),
          route: this.$getWebView('TasksEvent'),
          isDisabled: this.eventLocked, // Zamknute len ked nema hrac nema splneny karierny task 9.
          disabledText: this.eventType
            ? this.$replacePlaceholder(
                this.$t('common.mainCareerTaskRequired'),
                '%s',
                this.getMechanicUnlock(this.eventMechanicByType).toString(),
              )
            : '',
          isHidden:
            !this.eventMechanics.includes(EventMechanics.Taskchain) ||
            !this.eventActive ||
            this.eventTeaserActive ||
            this.eventCollectionActive, // Schovane ak nie je aktivna taskchain mechanika alebo event alebo je teaser alebo je preberacia faza.
        },
      }
    },
  },
  async created(): Promise<void> {
    await this.loadDisciplinesForMenu(MECHANIC_CAREER)
    await this.loadCareerDetail()

    this.disciplineId = Number(this.careerDetail.careerBranch.disciplineId) ?? 0
    this.questId = this.getActiveQuest.parameters.id
    this.careerId = this.careerDetail.careerId

    this.loadListOfUnlocks(this.careerId)

    this.dataReady = true
  },
  methods: {
    ...mapActions(useCareerDetailStore, ['loadCareerDetail']),
    ...mapActions(useListOfUnlocksStore, ['loadListOfUnlocks']),
    ...mapActions(useDisciplineStore, ['loadDisciplinesForMenu']),
    async goToCareerBranch(discipline: DisciplineMenuData): Promise<void> {
      if (!discipline.lock) {
        await this.loadCareerDetail(discipline.careerId.toString())

        this.careerId = discipline.careerId.toString()
        this.questId = this.getActiveQuest.parameters.id

        this.loadListOfUnlocks(this.careerId)
      } else {
        this.levelToUnlock = discipline?.levelToUnlock ?? 0
        this.temporaryLock = discipline?.temporaryLock ?? false
        this.completedQuests = discipline?.completedQuests ?? 0
      }

      this.disciplineId = discipline.id
      this.isDisciplineLocked = discipline.locked
    },
  },
})
</script>

<style lang="scss" scoped>
.tasks-career {
  main {
    width: 98%;
    height: 47.75rem;
    margin: 0 auto;
    margin-top: 2.5rem;

    .rightside {
      height: 47.25rem;
      width: 100%;

      &-unlocked {
        margin-left: 2rem;
      }

      &-footer {
        margin-top: 7.4rem;

        .header-info {
          background: none;
          border: none;
          height: auto;
          width: 40rem;
          text-align: left;

          &::before {
            content: none;
          }
        }
      }
    }
  }
}

.task-popup-main {
  height: 30.875rem;
  width: 64.3125rem;
  position: relative;

  &-text {
    width: 36.125rem;
    padding-top: 3.6875rem;
  }

  .icon-done {
    right: 6rem;
    top: 4rem;
  }

  .completed {
    &:after {
      position: absolute;
      right: -1rem;
      top: 60%;
      transform: translate(1rem, -50%);
      content: '';
      height: 100%;
      width: 0.4rem;
      background: linear-gradient(
        to bottom,
        rgba(63, 228, 67, 0),
        rgba(63, 228, 67, 1),
        rgba(63, 228, 67, 0)
      );
    }
  }

  .rewards {
    margin-top: 3.3125rem;
    height: 9.375rem;
    width: 28.125rem;
    text-shadow: 1.1px 1.7px 1px rgba(0, 0, 0, 0.46);

    &-header {
      background: linear-gradient(
        to right,
        rgba(251, 227, 110, 0),
        rgba(251, 227, 110, 1),
        rgba(251, 227, 110, 1),
        rgba(251, 227, 110, 0)
      );

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 0.125rem;
        left: 0rem;
        top: 0rem;
        background: linear-gradient(
          to right,
          rgba(255, 247, 200, 0),
          rgba(255, 247, 200, 1),
          rgba(255, 247, 200, 0)
        );
      }

      &:after {
        content: '';
        position: absolute;
        bottom: 0rem;
        left: 0rem;
        width: 100%;
        height: 0.125rem;
        background: linear-gradient(
          to right,
          rgba(255, 247, 200, 0),
          rgba(255, 247, 200, 1),
          rgba(255, 247, 200, 0)
        );
      }
    }

    &-box {
      height: 6.625rem;
    }
  }

  footer {
    margin-top: 2.875rem;
  }
}
</style>
