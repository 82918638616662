<template>
  <section
    v-if="isPaymentView ? paymentPassInfo : eventPass?.length"
    class="event-pass-detail-body flex flex-col flex-grow w-full"
    :data-event-theme="namespace"
  >
    <div
      class="event-pass-detail-body-cards flexing flex-grow w-full"
      :class="[pass.offer_content.length === 4 ? 'space-x-6' : 'space-x-16']"
    >
      <div
        v-for="(reward, i) in pass.offer_content"
        :key="i"
        v-tippy="{
          theme: 'WSM',
          content: isPaymentView ? getDescription(reward, i + 1) : '',
          placement: 'top',
          textAlign: 'center',
        }"
        class="event-pass-detail-body-card card"
        :class="[
          `tier-${isPaymentView ? tier : $route.query?.tier}`,
          {
            'is-payment-view': isPaymentView,
            'relative top-4': !isPaymentView,
          },
        ]"
      >
        <div class="card-image w-full -mt-4 mb-5 mx-10" :class="`reward-${i + 1}`" />
        <div class="card-header flexing w-full mt-5 mb-12 mx-12">
          <p class="flexing pt-2.5 text-50 gradient-text-gold text-center font-bold italic">
            {{ reward.value }}{{ getResourceUnit(reward.name, true) }}
          </p>
        </div>
        <div v-if="!isPaymentView" class="card-footer flexing w-full mt-12 mx-12">
          <p class="flexing text-30 text-texts-standard-default text-center font-bold italic">
            {{ getDescription(reward, i + 1) }}
          </p>
        </div>
      </div>
    </div>
    <div v-if="!isPaymentView" class="event-pass-detail-footer flexing w-full my-12 relative">
      <template v-if="!pass.remaining_buys">
        <p
          class="flexing text-50 text-texts-standard-important text-center font-bold italic uppercase mx-auto"
          :class="{
            purchased: !pass.remaining_buys && currentPassIndex !== activePassIndex,
            activated: !pass.remaining_buys && currentPassIndex === activePassIndex,
          }"
        >
          <template v-if="currentPassIndex === activePassIndex">
            {{ $te('activated') }}
            <app-icon icon-name="done-md" class="inline-block ml-4" />
          </template>
          <template v-else>{{ $te('purchased') }}</template>
        </p>
      </template>
      <div v-else>
        <p
          v-if="hasOfferOldPrice(pass)"
          class="flexing text-40 text-texts-standard-additional font-normal space-x-2 absolute -top-18 left-1/2 transform -translate-x-1/2"
        >
          <span class="price-crossed inline-flex relative">{{ getOfferOldPrice(pass) }}</span>
          <span class="inline-flex">{{ pass.price.currency }}</span>
        </p>
        <buy-pass-button
          :item-id="pass.store_id"
          :price="pass.price.price"
          :currency="pass.price.currency"
          :is-large="true"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { premiumEventShop } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import type { PremiumPacksInterface } from '@/interfaces/premium/Offer'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import BuyPassButton from '../../BuyPassButton.vue'
import { getResourceUnit } from '@/helpers/getResourceUnit'
import { hasOfferOldPrice, getOfferOldPrice } from '@/helpers/getOfferOldPrice'
import type PremiumEventPassApiResponse from '@/interfaces/responses/premium/PremiumEventPassApiResponse'

export default defineComponent({
  name: 'EventPass3DetailBody',
  components: { BuyPassButton },
  props: {
    isPaymentView: {
      type: Boolean,
      default: false,
    },
    storeId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      paymentPassInfo: null,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    activePassIndex(): number {
      return this.eventPass.findLastIndex(
        ({ remaining_buys }: PremiumPacksInterface['packs'][0]): boolean => remaining_buys === 0,
      )
    },
    currentPassIndex(): number {
      return Number(this.$route.query?.tier) - 1
    },
    pass(): PremiumEventPassApiResponse['packs'][0] {
      return this.isPaymentView ? this.paymentPassInfo : this.eventPass[this.currentPassIndex]
    },
    tier(): number {
      return Number(
        this.pass?.parameters?.find(
          ({ name }: PremiumEventPassApiResponse['packs'][0]['parameters'][0]): boolean =>
            name === 'pass_tier',
        )?.value,
      )
    },
  },
  async created(): Promise<void> {
    if (!this.isPaymentView || !this.storeId) return

    try {
      this.paymentPassInfo = (
        await this.$axios.get<{}, PremiumEventPassApiResponse>(
          `${premiumEventShop}?storeId=${this.storeId}`,
        )
      )?.packs?.pop()
    } catch (error: unknown) {
      console.error(error)
    }
  },
  methods: {
    getResourceUnit,
    hasOfferOldPrice,
    getOfferOldPrice,
    getDescription(
      reward: PremiumEventPassApiResponse['packs'][0]['offer_content'][0],
      index: number,
    ): string {
      return this.$replacePlaceholder(
        this.$te(`eventPassPerk${index}${reward.value === 1 ? 'A' : 'B'}`),
        '{2to4}|%s',
        `${reward.value}${this.getResourceUnit(reward.name, true)}`,
      )
    },
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);
$rewards: (1, 2, 3, 4);

[data-event-theme='frozen'] {
  @each $tier in $tiers {
    --events-passes-event-card-#{$tier}-detail-bg: url('#{$path-events}frozen-championship/passes/event-pass/card-#{$tier}-detail-bg.webp');
    --events-passes-event-payment-card-#{$tier}-detail-bg: url('#{$path-events}frozen-championship/passes/event-pass/payment-card-#{$tier}-detail-bg.webp');
  }

  @each $reward in $rewards {
    --events-passes-event-card-reward-#{$reward}-bg: url('#{$path-events}frozen-championship/passes/event-pass/reward-#{$reward}-bg.webp?v=1');
  }
}

.event-pass-detail {
  &-body {
    .card {
      width: 28.563rem;
      height: 37.438rem;
      @include background(null, contain);

      @each $tier in $tiers {
        &.tier-#{$tier} {
          &:not(.is-payment-view) {
            background-image: var(--events-passes-event-card-#{$tier}-detail-bg);
          }

          &.is-payment-view {
            height: 21.25rem;
            background-image: var(--events-passes-event-payment-card-#{$tier}-detail-bg);
          }
        }
      }

      &-image {
        width: 23.875rem;
        height: 13.5rem;
        @include background(null, contain);

        @each $reward in $rewards {
          &.reward-#{$reward} {
            background-image: var(--events-passes-event-card-reward-#{$reward}-bg);
          }
        }
      }

      &-header {
        width: 23rem;
        height: 5rem;
      }

      &-footer {
        width: 23rem;
        height: 11.5rem;
      }
    }
  }

  &-footer {
    .activated,
    .purchased {
      width: 40rem;
      border-style: solid;
      border-width: 0.188rem;
      border-image-slice: 1;
    }

    .activated {
      background-image: linear-gradient(
        90deg,
        transparent,
        #0e4503,
        rgba(19, 89, 5, 0.97),
        #1a7708,
        rgba(19, 89, 5, 0.97),
        #0e4503,
        transparent
      );
      border-image-source: linear-gradient(
        90deg,
        transparent,
        #ff9600,
        #358f23,
        #ff9600,
        transparent
      );
    }

    .purchased {
      background-image: linear-gradient(
        90deg,
        transparent,
        #654008,
        rgba(124, 82, 17, 0.97),
        #b66c03,
        rgba(124, 82, 17, 0.97),
        #654008,
        transparent
      );
      border-image-source: linear-gradient(
        90deg,
        transparent,
        #ff9600,
        #f09008,
        #ff9600,
        transparent
      );
    }

    .price-crossed::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 0.25rem;
      background-color: #b52e2d;
      transform: translate(-50%, -50%) skewY(-14deg);
    }
  }
}
</style>
