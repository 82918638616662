<template>
  <div class="menu-wrapper h-auto w-full safe-area-x">
    <div
      :id="'app-menu-' + menuType"
      :daty-cy="'app-menu-' + menuType"
      class="modal-menu relative flex justify-end items-center pr-8"
      :class="'app-menu-' + menuType"
    >
      <div
        class="modal-title flex items-center justify-left absolute m-4 text-texts-standard-default"
      >
        <div class="left-strip absolute left-0 h-full" />
        <div class="middle-strip absolute left-0 h-full" />
        <span
          class="modal-title-text text-48 uppercase"
          :class="{ 'revert-skew': currentGame === GameType.Winter }"
          >{{ title }}</span
        >
        <tippy theme="WSM" placement="right">
          <app-icon v-if="isIcon" icon-name="info-50" class="modal-title-icon" />
          <template #content>
            <div v-html="isIcon" />
          </template>
        </tippy>
      </div>
      <section v-if="menuItems" class="flex items-center justify-end">
        <router-link
          v-for="(item, index) in menuItems"
          :key="index"
          :to="getRoute(item)"
          class="item-menu items-center flex text-center justify-center text-2xl align-middle m-2 relative"
        >
          <app-button
            :btn-type="
              item.route == $route.name || item.current == $route.name ? 'secondary' : 'inactive'
            "
            :btn-text="item.text"
            @click="playSound('click-item-dialog-menu')"
          />
          <!-- TODO Napojit notifikacie pre postu -->
          <app-notification
            v-if="item.notification || getAppNotifications(item.route)"
            :count="item.notification || getAppNotifications(item.route)"
          />
        </router-link>
      </section>
      <slot />
      <div
        v-if="rightBoxIcon && !isTooltip"
        class="menu-info-box flex justify-between items-center no-margin"
      >
        <div class="flexing">
          <app-main-icon :icon-size="56" :icon-name="rightBoxIcon" class="menu-info-box-icon" />
          <div class="menu-info-box-text text-texts-standard-default text-40 font-bold italic">
            {{ $filters.$formatNumber(rightBoxValue) }}
          </div>
        </div>
        <app-icon
          class="revert-skew"
          :class="{
            'mr-2': $isSsm,
          }"
          icon-name="info-50"
          @click="$emit('click')"
        />
      </div>
      <tippy v-if="isTooltip" theme="WSM1" placement="bottom" max-width="42.563rem">
        <template #content>
          <div class="lab-tooltip">
            <h2 class="text-texts-standard-important text-32 font-bold my-4">
              {{ $replaceUntranslatablePlaceholder($t('research.researchPoints'), '{grandprize}') }}
            </h2>
            <div class="w-2/3 m-auto">
              <div class="yellow-strip" />
            </div>
            <p class="text-texts-standard-default text-30 my-4">
              {{ $t('research.resPoints') }}
            </p>
            <div class="w-2/3 m-auto">
              <div class="yellow-strip" />
            </div>
            <p class="text-texts-standard-default text-30 my-4">
              {{ $replaceUntranslatablePlaceholder($t('research.getResPoints'), '{grandprize}') }}
            </p>
          </div>
        </template>
        <div
          v-if="rightBoxIcon && rightBoxValue !== -1"
          class="menu-info-box flex justify-between items-center"
        >
          <aside class="flexing">
            <app-main-icon :icon-size="72" :icon-name="rightBoxIcon" class="menu-info-box-icon" />
            <div class="menu-info-box-text">
              <p class="text-texts-standard-default text-40 font-bold">
                {{ $filters.$formatNumber(rightBoxValue) }}
              </p>
            </div>
          </aside>
          <app-icon icon-name="info-50" class="revert-skew mx-2 click-revert" />
        </div>
      </tippy>

      <div v-if="isPremiumSelectRoute" class="premium-right-side">
        <premium-country-select @select-country="selectCountry" />
      </div>
    </div>

    <section v-if="subMenu" class="submenu italic pl-20 w-full flex text-xl">
      <router-link
        v-for="(sub, index) in subMenu as Record<string, (typeof subMenu)[keyof typeof subMenu]>"
        :id="index === '2' && isClickOnPersonalCoach ? 'menu-coach' : null"
        :key="index"
        v-tippy="
          sub.isDisabled
            ? {
                theme: 'WSM',
                content: sub.isDisabled ? sub.disabledText : '',
                placement: 'bottom',
                textAlign: 'center',
              }
            : {}
        "
        :to="getRoute(sub)"
        class="submenu-box relative"
        :class="{
          activeSubMenu: sub.active || [sub.route, sub.current].includes($route.name as string),
          hidden: sub?.isHidden,
          'locked-submenu': sub.isDisabled,
          'button-anim': index === '2' && isClickOnPersonalCoach,
        }"
        @click="playSound('click-item-dialog-submenu')"
      >
        <arrow-animation
          :add-id="index === '2' && isClickOnPersonalCoach ? 'menu-coach' : ''"
          position="right"
          border="button"
          :tutorial="index === '2' && isClickOnPersonalCoach"
        >
          <div
            :id="index === '2' && isClickOnPersonalCoach ? 'menu-coach' : null"
            class="submenu-notification-wrapper flexing flex-row relative"
          >
            <span class="relative px-4 pointer-events-none">
              {{ sub.text }}
              <app-notification
                v-if="showSubMenuNotification(sub, index)"
                :type="getNotificationType(sub.route ?? '')"
                :count="getNotificationsByType(index, sub)"
              />
            </span>
            <app-icon
              v-show="sub.isDisabled"
              icon-name="lock-xsm"
              class="relative pointer-events-none"
            />
          </div>
        </arrow-animation>
      </router-link>
      <div class="pass-info absolute">
        <slot name="subMenuExtraContent" />
        <slot name="subMenuExtraContent2" />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import AppNotification from '@/components/GlobalComponents/AppNotification.vue'
import { playSound } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import ArrowAnimation from '../ArrowAnimation.vue'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { GameType, PREMIUM } from '@/globalVariables'
import PremiumCountrySelect from '@/components/Premium/PremiumCountrySelect.vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import { useAccountStore } from '@/store/pinia/accountStore'

interface MenuItem {
  text: string
  route: string
  current?: string
  active?: boolean
  isDisabled?: boolean
  disabledText?: string
  isHidden?: boolean
  id?: number
  task?: string
  notification?: number
}

interface MenuData {
  [key: number]: MenuItem
}

interface ComponentData {
  playSound: typeof playSound
  GameType: typeof GameType
  currentGame: string
  premiumRoutes: string[]
  isPremiumSelectRoute: boolean
}

export default defineComponent({
  name: 'MenuComponent',
  components: {
    AppNotification,
    ArrowAnimation,
    PremiumCountrySelect,
  },
  props: {
    menuType: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    menuItems: {
      type: Object as PropType<Nullable<MenuData>>,
      default: () => null,
    },
    subMenu: {
      type: Object as PropType<Nullable<MenuData>>,
      default: () => null,
    },
    rightBoxIcon: {
      type: String,
      default: '',
    },
    rightBoxValue: {
      type: Number,
      default: -1,
    },
    isIcon: {
      type: String,
      default: '',
    },
    showHomepageScene: {
      type: Boolean,
      default: false,
    },
    isTooltip: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click', 'select-country'],
  data(): ComponentData {
    return {
      playSound,
      GameType,
      currentGame: this.$gameName,
      premiumRoutes: ['PremiumOffers', 'PremiumGems', 'PremiumWeeklyDeals'],
      isPremiumSelectRoute: false,
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      actualStageData: 'getActualStage',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    ...mapState(useAccountStore, ['hasVerifiedAccount']),
    isClickOnPersonalCoach(): boolean {
      return this.actualStageData?.name === 'clickOnTrainingHallTrainerTab'
    },
  },
  mounted(): void {
    if (this.menuType === PREMIUM) {
      this.isPremiumSelectRoute = this.premiumRoutes.some(
        (route: string): boolean => this.$route.name === this.$getWebView(route),
      )
    }
  },
  methods: {
    ...mapActions(usePhaserGameIntegrationStore, {
      setPremiumNotification: 'setPremiumNotification',
    }),
    showSubMenuNotification(sub: MenuItem, index: string): boolean {
      if (sub.isDisabled) return false

      const route = this.getRoute(sub)
      switch (this.menuType) {
        case 'tasks':
        case 'dailyTask':
          return !!this.getNotificationsByType(index, sub)
        case 'premium':
          return !!this.getAppNotifications(sub)
        case 'club-management-menu':
          return !!this.getAppNotifications(typeof route === 'string' ? route : (route.name ?? ''))
        case 'training':
          return !!this.getAppNotifications(typeof route === 'string' ? route : (route.name ?? ''))
        case 'lab':
          return !!this.getAppNotifications(sub)
        case 'settings':
          return sub.route === this.$getWebView('Account') ? !this.hasVerifiedAccount : false
        default:
          return false
      }
    },
    getRoute(item: MenuItem): string | Record<string, unknown> {
      if (item.isDisabled) {
        return ''
      }
      return item.id
        ? item.task
          ? {
              name: item.route,
              params: {
                id: item.id,
                task: item.task,
              },
              query: {
                homepage: this.showHomepageScene ? this.showHomepageScene : undefined,
                redirectFrom: this.$route.query.redirectFrom ?? undefined,
              },
            }
          : {
              name: item.route,
              params: {
                id: item.id,
              },
              query: {
                homepage: this.showHomepageScene ? this.showHomepageScene : undefined,
                redirectFrom: this.$route.query.redirectFrom ?? undefined,
              },
            }
        : {
            name: item.route,
            query: {
              homepage: this.showHomepageScene ? this.showHomepageScene : undefined,
              redirectFrom: this.$route.query.redirectFrom ?? undefined,
            },
          }
    },
    getNotificationType(target: string): string {
      if (target === this.$getWebView('Account')) return 'exclamationLabel'

      return target.includes('PremiumOffers') || target.includes('PremiumWeeklyDeals')
        ? 'newLabel'
        : 'numberLabel'
    },
    // TODO: refactor this function, it cannot return every possible type
    getAppNotifications(targetRoute): any {
      // Check if we have some notifications in premium - if its empty, remove animated notification above premium building
      if (this.$route.path.includes('/premium/')) {
        this.setPremiumNotification()
      }

      if (this.$route.path.includes('/grand-prize/') && targetRoute.includes('GrandPrizeHistory'))
        return this.notifications.grand_prize_unclaimed
      if (this.$route.path.includes('/premium/') && targetRoute.route.includes('PremiumWildCard'))
        return (
          // this.notifications.wild_card_addon_free ||
          // this.notifications.energy_drink_addon_free ||
          this.notifications.video_ads_free_starts_show ||
          this.notifications.video_ads_free_energy_show
        )
      if (this.$route.path.includes('/premium/') && targetRoute.route.includes('PremiumGems'))
        return this.notifications.can_claim_ret_payment ||
          this.notifications.video_ads_free_gems_show
          ? 1
          : 0
      if (this.$route.path.includes('/premium/') && targetRoute.route.includes('PremiumOffers'))
        return this.notifications.offer ? true : ''
      if (
        this.$route.path.includes('/premium/') &&
        targetRoute.route.includes('PremiumWeeklyDeals')
      )
        return this.notifications.weekly ? 1 : 0
      if (this.$route.path.includes('/clubs/') && targetRoute.includes('ClubsApplication'))
        return this.notifications.clubs_requests ? this.notifications.clubs_requests : 0
      if (this.$route.path.includes('/training/') && targetRoute.includes('TrainingTrainer')) {
        return this.notifications.personal_trainer_count
      }
      if (this.$route.path.includes('/lab/')) {
        const treeType = this.getResearchTreeTypeByRoute(targetRoute.route)
        const tree = this.notifications.research_notification?.trees?.find((e) => {
          return e.tree === treeType
        })
        return tree?.notifications ?? false
      }
      return 0
    },
    getNotificationsByType(submenuIndex: string, submenu: MenuItem): number {
      if (this.$route.path.includes('/clubs/') && submenu.route.includes('ClubsApplication')) {
        return this.notifications.clubs_requests ? this.notifications.clubs_requests : 0
      }

      if (this.$route.path.includes('/premium') && submenu.route.includes('PremiumWildCard')) {
        return (
          // this.notifications.wild_card_addon_free + this.notifications.energy_drink_addon_free ||
          +this.notifications.video_ads_free_starts_show +
          +this.notifications.video_ads_free_energy_show
        )
      }

      if (this.$route.path.includes('/premium/') && submenu.route.includes('PremiumGems')) {
        return this.notifications.can_claim_ret_payment ||
          this.notifications.video_ads_free_gems_show
          ? 1
          : 0
      }

      if (this.$route.path.includes('/lab/')) {
        const treeType = this.getResearchTreeTypeByRoute(submenu.route)
        const tree = this.notifications.research_notification?.trees?.find((e) => {
          return e.tree === treeType
        })
        return tree.count ?? 0
      }

      if (this.$route.path.includes('/training/') && submenu.route.includes('TrainingTrainer')) {
        return this.notifications.personal_trainer_count
      }

      if (submenuIndex === '1') {
        return this.notifications.daily_tasks.length
      }

      if (submenuIndex === '2') {
        return this.notifications.career_quests.length
      }
    },
    getResearchTreeTypeByRoute(route: string): string {
      if (route.includes('LabDisciplines')) {
        return 'discipline'
      }

      if (route.includes('LabEconomics')) {
        return 'economy'
      }

      if (route.includes('LabCamps')) {
        return 'camps'
      }

      return ''
    },
    selectCountry(country: string): void {
      this.$emit('select-country', country)
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/menu.scss';

.menu-wrapper {
  margin-top: 5.875rem;
}

.menu-info-box {
  min-width: 16.875rem;
  height: 4.063rem;
  transform: $skewX-value;
  border-radius: 0.125rem;

  @if $isWsm {
    background-color: rgb(15, 50, 81);
    border: 0.125rem solid #f4d91e;
    box-shadow:
      inset -0.4375rem 0 1.3125rem 0 rgba(51, 123, 214, 0.19),
      inset -0.019625rem -0.1865rem 2.5rem 0 rgba(6, 34, 55, 0.48);
  }

  @if $isSsm {
    background-color: #363e5d;
    border-style: solid;
    border-width: 0.125rem;
    border-image-source: linear-gradient(to right, #f6c717, #fff284);
    border-image-slice: 1;
    box-shadow:
      inset -3px 0px 40px 0 rgba(6, 34, 55, 0.48),
      inset -0.3px -3px 40px 0 rgba(6, 34, 55, 0.48);
  }

  &-icon {
    transform: scale(0.7) $skewX-revert-value;
  }

  &-text {
    transform: $skewX-revert-value;
  }

  .click-revert {
    &:active {
      transform: $skew-revert-value scale(0.95);
    }
  }
}

.modal-title-icon {
  margin-left: 2.5rem;
  transform: $skewX-revert-value;
}

.lab-tooltip {
  width: 42.563rem;
  min-height: 11.25rem;
  padding: 0 3rem 1.5rem;

  &-up {
    height: 3.5rem;
    background: linear-gradient(to right, transparent, #236d9b20, #236d9b, #236d9b20, transparent);

    &:after {
      content: '';
      position: absolute;
      width: 80%;
      height: 0.125rem;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      background: linear-gradient(to right, transparent, rgba(255, 255, 255, 0.486), transparent);
    }
  }

  &-down {
    height: 8.125rem;
  }

  .no-margin {
    margin-top: 0 !important;
    padding-left: 1rem;
    padding-right: 0.5rem;
  }
}

.submenu-notification-wrapper {
  .notification-icon {
    right: -2.2rem;
  }
}

.pass-info {
  right: $default-margin;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.premium-right-side {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-left: auto;
  font-size: 2rem;
}
</style>
