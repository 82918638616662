<template>
  <header class="teaser-header flex flex-col items-end" :data-event-theme="namespace">
    <section class="teaser-header-time text-40 text-texts-standard-important font-bold flexing">
      <p v-if="eventTeaserActive">{{ $te('eventStart') }}</p>
      <p v-else>{{ $te('eventEnd') }}</p>
      <app-timer
        class="timer"
        text-size="2.5rem"
        transparent
        :time="eventTimer"
        @countdown-action="loadEvent(true)"
      />
    </section>
    <div class="teaser-header-header w-full flex justify-end items-center">
      <div
        class="teaser-header-header-logo"
        :class="{
          [`teaser-header-logo-${eventDisciplineId}`]: !!eventDisciplineId,
          'is-ssm-gc': $isSsm && namespace === EventType.FrozenChampionship,
        }"
      />
    </div>
  </header>
</template>

<script lang="ts">
import { EventType } from '@/interfaces/events/EventInfo'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  EventType: typeof EventType
}

export default defineComponent({
  name: 'TeaserHeader',
  data(): ComponentData {
    return {
      EventType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventDisciplineId: 'getEventDisciplineId',
      eventTeaserActive: 'getEventTeaserActive',
      eventTimer: 'getEventLeftBoxTimer',
    }),
  },
  async created(): Promise<void> {
    await this.loadEvent(true)
  },
  methods: {
    ...mapActions(useEventInfoStore, ['loadEvent']),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-teaser-header-bg: url('#{$path-events}challenge-cup/teaser/teaser-header-bg.webp');
  --events-teaser-header-logo: url('#{$path-events}challenge-cup/teaser/teaser-header-logo.webp');
}

[data-event-theme='frozen'] {
  --events-teaser-header-logo: url('#{$path-events}frozen-championship/teaser/teaser-header-logo.webp');

  @if $isWsm {
    --events-teaser-header-bg: url('#{$path-events}frozen-championship/teaser/teaser-header-bg.webp');
  }

  @if $isSsm {
    --events-teaser-header-bg: none; // je zapecene v pozadi
  }
}

[data-event-theme='discipline'] {
  --events-teaser-header-bg: url('#{$path-events}discipline-event/teaser/teaser-header-bg.webp');

  @for $i from 1 through 20 {
    --events-teaser-header-logo-#{$i}: url('#{$path-events}discipline-event/teaser/teaser-header-logo-#{$i}.webp');
  }
}

.teaser-header {
  margin-top: 8rem;

  &-time {
    width: 68.5rem;
    height: 3.75rem;
    background: linear-gradient(to right, transparent, #941818, transparent);
    margin-top: -0.6rem;

    .timer {
      background: none;

      &::before {
        background: none;
      }
    }
  }

  &-header {
    height: 7.3125rem;
    margin-top: 1.5rem;
    @include background(var(--events-teaser-header-bg), contain, right);

    &-logo {
      height: 7.375rem;
      width: 56.9375rem;
      @include background(var(--events-teaser-header-logo), contain);
      margin-right: 9.5rem;

      &:not(.is-ssm-gc) {
        margin-top: 1rem;
      }

      &.is-ssm-gc {
        margin-top: 0;
      }

      @for $i from 1 through 20 {
        &.teaser-header-logo-#{$i} {
          background-image: var(--events-teaser-header-logo-#{$i});
          margin-top: 0;

          @if $isWsm {
            width: 31.25rem;
            height: 2.75rem;
          }

          @if $isSsm {
            width: 25rem;
            height: 5.25rem;
          }
        }
      }
    }
  }
}
</style>
