<template>
  <button
    :id="btnId"
    :data-cy="btnId"
    class="clickable-skew-element flex justify-center items-center italic btn"
    :class="[
      'btn-' + btnType,
      'btn-size-' + btnSize,
      addClass,
      disabled ? 'disabled' : 'active',
      {
        'btn-locked': locked,
        'allow-click': allowClick,
        // 'btn-icon': btnIcon,
      },
    ]"
    @click.passive="!disabled && $emit('click', $event)"
  >
    <app-notification
      v-if="showNotifications()"
      :type="notificationType"
      :count="getNotificationCount()"
      :is-skewed-parent="true"
    />
    <div v-if="btnIcon" :data-cy="btnId + '-icon'" :class="'btn-icon ' + btnIcon" />

    <shrink-text
      v-if="btnText"
      :text="btnText"
      :font-size="buttonFontSize"
      :container-class-list="`flexing px-2 ${textUpperCase ? 'uppercase' : ''}`"
      :text-class-list="{ 'font-bold': $isWsm, 'font-medium': $isSsm }"
      :animated-overflow="true"
    />
    <section
      v-else
      :data-cy="btnId + '-text'"
      class="btn-text flexing leading-7 pt-1.5 flex-col"
      :class="{ uppercase: textUpperCase, 'font-bold': $isWsm, 'font-medium': $isSsm }"
    >
      <slot />
    </section>
    <div v-if="locked" class="locked w-full h-full absolute top-0 left-0 flexing">
      <span class="icon-lock-sm revert-skew" />
    </div>
    <div
      v-if="loading"
      class="absolute revert-skew loading-btn top-0 right-0 w-full h-full"
      style="transform: skewX(0deg)"
    >
      <component-loading height="100%" width="100%" :is-loading="true" />
    </div>
  </button>
</template>

<script lang="ts">
import { createRandomId } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

interface ComponentData {
  icon: string
}

export default defineComponent({
  name: 'AppButton',
  components: {
    ShrinkText,
  },
  props: {
    btnId: {
      type: String,
      default: createRandomId('button'),
    },
    btnText: {
      type: String,
      default: null,
    },
    btnType: {
      type: String,
      default: 'primary',
      validator(value: string): boolean {
        return ['primary', 'secondary', 'credit', 'danger', 'inactive', 'confirm'].includes(value)
      },
    },
    btnSize: {
      type: String,
      default: 'md',
      validator(value: string): boolean {
        return ['xxsm', 'xsm', 'sm', 'md', 'xl'].includes(value)
      },
    },
    btnIcon: {
      type: String,
      default: '',
    },
    addClass: {
      type: String,
      default: '',
    },
    locked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    allowClick: {
      type: Boolean,
      default: false,
    },
    notificationsCount: {
      type: Number,
      default: 0,
    },
    textUpperCase: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    forceNotification: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  data(): ComponentData {
    return {
      icon: '',
    }
  },
  computed: {
    ...mapState(useTutorialStore, {
      isTutorial: 'getIsTutorial',
    }),
    ...mapState(useResponseTaskStore, {
      notifications: 'getNotifications',
    }),
    notificationType(): string {
      if (this.isExclamationNotification) return 'exclamationLabel'
      return this.notifications.weekly > 0 || this.notifications.offer > 0
        ? 'newLabel'
        : 'numberLabel'
    },
    premiumNotificationCount(): number {
      return (
        +this.notifications.can_claim_ret_payment +
        +this.notifications.video_ads_free_gems_show +
        +this.notifications.video_ads_free_starts_show +
        +this.notifications.video_ads_free_energy_show
      )
    },
    isExclamationNotification(): boolean {
      return this.btnId.endsWith('-exclamation')
    },
    buttonFontSize(): number {
      switch (this.btnSize) {
        case 'xxsm':
          return 36
        case 'xsm':
          return 36
        case 'sm':
          return 36
        case 'md':
          return 36
        case 'xl':
          return 36
        default:
          return 36
      }
    },
  },
  created(): void {
    if (this.btnType === 'credit') {
      this.icon = 'icon-credit'
    } else {
      this.icon = this.btnIcon
    }
  },
  methods: {
    showNotifications(): boolean {
      if (this.isExclamationNotification || this.forceNotification) return true
      if (this.btnId === 'layout-premium-btn' && !this.isTutorial) {
        return (
          // this.notifications.wild_card_addon_free ||
          this.notifications.weekly > 0 ||
          // this.notifications.energy_drink_addon_free ||
          this.notifications.video_ads_free_starts_show ||
          this.notifications.video_ads_free_energy_show
        )
      } else return false
    },
    getNotificationCount(): number {
      return this.notificationsCount > 0 ? this.notificationsCount : this.premiumNotificationCount
    },
  },
})
</script>

<style lang="scss" scoped>
.locked {
  span {
    background-size: cover;
    background-position: center center;
  }
}

.loading-btn {
  background: rgba($color: #254257, $alpha: 0.8);
}
</style>
