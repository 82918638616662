<template>
  <section :data-event-theme="namespace" class="club-mls-middle flexing relative">
    <app-scrollbar
      class="flex club-mls-middle-scroll items-center"
      width="100%"
      height="100%"
      scroll="x"
      slice="x"
      :arrows-z-index="10"
      :arrows="true"
      :scroll-to-element="`#milestone-${currentMilestone}`"
    >
      <div
        v-for="(task, index) in clubTasks"
        :id="`milestone-${index}`"
        :key="index"
        class="rewards-section h-full flex justify-center items-center relative"
        :class="{ 'rewards-section--locked-future': index > currentMilestone }"
      >
        <div
          v-if="index !== 0"
          class="rewards-section-box flexing relative"
          :class="{ 'rewards-section-box--done': index <= currentMilestone }"
        >
          <app-main-icon :icon-name="task.reward[0]?.type" :icon-size="90" />
          <p class="text-texts-standard-default text-40 mt-1">
            {{ $filters.$formatNumber(task.reward[0]?.value) }}
          </p>
          <app-icon
            v-if="index <= currentMilestone"
            class="absolute right-0 bottom-0"
            icon-name="done-md"
          />
          <app-icon v-else class="absolute right-0 bottom-0" icon-name="plus-green" />
        </div>
        <div v-else class="rewards-section-basic text-40 uppercase flexing font-bold">
          <p class="gradient-text-light pr-4">
            {{ $te('clubRewards') }}
          </p>
        </div>
        <tippy theme="WSM1" class="flexing" placement="top" max-width="50rem">
          <template #content>
            <div class="lock-tooltip">
              <div class="lock-tooltip-title flexing">
                <p class="uppercase font-bold text-30">
                  {{ $te('eventPointsRequired') }}
                </p>
              </div>
              <p class="lock-tooltip-text-margin flexing text-28">
                <b>{{ $filters.$formatNumber(task.points) }}</b>
                <app-main-icon :icon-size="56" icon-name="event_points" class="ml-1" />
              </p>
            </div>
          </template>
          <div
            class="rewards-section-milestone relative flexing text-60"
            :class="{
              'is-claimed': currentMilestone > task.milestone,
              'is-active': currentMilestone === task.milestone,
            }"
          >
            <p
              class="gradient-text-light font-bold"
              :class="index <= 9 ? 'centrer--one-digit-number' : 'centrer--two-digit-number'"
            >
              {{ index }}
            </p>
            <app-icon
              v-if="index > currentMilestone"
              class="absolute box-icon-lock"
              icon-name="lock-sm"
            />
          </div>
        </tippy>
        <div v-show="index !== clubTasks.length - 1" class="progress-wrapper absolute">
          <div
            class="progress-wrapper-slide"
            :class="{
              'progress-wrapper-slide--actual-progress': currentMilestone === task.milestone,
            }"
            :style="{ width: calculateProgress(task.milestone) + '%' }"
          />
        </div>
      </div>
    </app-scrollbar>
    <section v-if="!hasClub" class="flexing w-full h-full z-10 absolute">
      <div class="club-lock-overlay w-full h-full absolute" />
      <div class="club-lock w-full flexing text-40 text-texts-standard-default font-bold z-20">
        <app-icon icon-name="lock-lg mr-12" />
        <p class="club-lock-text">{{ $te('getClub') }}</p>
      </div>
    </section>
  </section>
</template>

<script lang="ts">
import { useUserStore } from '@/store/pinia/userStore'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type MilestonesConfigApiResponse from '@/interfaces/responses/events/milestones/MilestonesConfigApiResponse'
import type MilestonesStateApiResponse from '@/interfaces/responses/events/milestones/MilestonesStateApiResponse'

export default defineComponent({
  name: 'ClubMilestonesMiddle',
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useMilestonesStore, {
      _clubTasks: 'getClubBranchTasks',
      _currentMilestonePoints: 'getCurrentMilestonePoints',
      currentMilestone: 'getCurrentMilestoneNumber',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    currentMilestonePoints(): MilestonesStateApiResponse['milestone'] {
      if (!this.hasClub) return { number: 0, points: 0, next_points: 200, next_milestone: 1 }
      return this._currentMilestonePoints
    },
    clubTasks(): MilestonesConfigApiResponse['branches'][0]['milestones'][0][] {
      return [
        {
          milestone: 0,
          reward: [],
          points: 0,
        },
        ...this._clubTasks,
      ]
    },
  },
  methods: {
    calculateProgress(value: number): number {
      if (!this.hasClub) return 0
      if (value === this.currentMilestone)
        return (this.currentMilestonePoints.points / this.currentMilestonePoints.next_points) * 100
      else if (value < this.currentMilestone) return 100
      else return 0
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  @if $isWsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
    --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
    --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-cc.avif');
    --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle-cc.avif');
    --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active-cc.avif');
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done-cc.avif');
  }

  --club-milestones-club-reward-box: url('#{$path-events}challenge-cup/milestones/milestones-club-reward-box.avif');
  --club-milestones-basic-box: url('#{$path-events}challenge-cup/milestones/milestones-basic-box.avif');
}

[data-event-theme='frozen'] {
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper.avif');
  --milestones-circle: url('#{$path-events}shared/milestones/milestones-circle.avif');
  --milestones-circle-active: url('#{$path-events}shared/milestones/milestones-progress-active.avif');

  @if $isWsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle.avif');
  }

  @if $isSsm {
    --milestones-circle-done: url('#{$path-events}shared/milestones/milestones-circle-done.avif');
  }

  --club-milestones-club-reward-box: url('#{$path-events}frozen-championship/milestones/milestones-club-reward-box.avif');
  --club-milestones-basic-box: url('#{$path-events}frozen-championship/milestones/milestones-basic-box.avif');
}

.club-mls-middle {
  height: 28.625rem;
  top: -0.75rem;

  &-scroll {
    margin: 0 2.125rem;
  }

  .rewards-section {
    flex-direction: column;
    width: 12rem;
    width: 14.25rem;
    padding: 0 2.938rem;

    &:first-child {
      width: 16.125rem;
      padding: 0 calc(2.125rem / 2);

      .progress-wrapper {
        width: 11rem;
        left: 10rem;
      }
    }

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 75%;
      top: 0;
      z-index: 0;
    }

    &:nth-child(even) {
      &::before {
        background: rgba(15, 21, 44, 0.6);
        background: linear-gradient(0deg, rgba(15, 21, 44, 0.6) 0%, rgba(15, 21, 44, 0) 100%);
      }
    }

    &:nth-child(2n - 1) {
      &::before {
        background: rgba(52, 63, 105, 0.6);
        background: linear-gradient(0deg, rgba(52, 63, 105, 0.6) 0%, rgba(52, 63, 105, 0) 100%);
      }
    }

    &:first-child {
      width: 16rem;

      .progress-wrapper {
        width: 11rem;
        left: 10rem;
      }
    }

    &:first-child {
      &::before {
        content: none;
      }
    }

    &--locked-future {
      .rewards-section-box {
        opacity: 0.4;
      }
    }

    &-box {
      @include background(var(--club-milestones-club-reward-box), contain);
      width: 11.3125rem;
      height: 13.8125rem;
      flex-direction: column;

      &--done {
        @include background(
          url('#{$path-events}shared/milestones/milestones-box-done.avif'),
          contain
        );
      }
    }

    &-basic {
      @include background(var(--club-milestones-basic-box), contain);
      width: 15.25rem;
      height: 13.75rem;
    }

    &-milestone {
      @include background(var(--milestones-circle), contain);
      width: 7.313rem;
      height: 7.375rem;
      z-index: 9;

      .centrer {
        &--one-digit-number {
          margin-left: 0rem;
          margin-bottom: -0.15rem;
        }

        &--two-digit-number {
          margin-left: -0.35rem;
          margin-bottom: -0.15rem;
        }
      }

      .box-icon-lock {
        right: 0.15rem;
        bottom: 0.5rem;
      }
    }

    .is-active {
      @include background(var(--milestones-circle-active), contain);
    }

    .is-claimed {
      @include background(var(--milestones-circle-done), contain);
    }

    .progress-wrapper {
      @include background(var(--milestones-progress-bg), contain, center, repeat);
      width: 10.5rem;
      height: 8.5625rem;
      left: 9rem;
      top: 16.5rem;
      z-index: 7;

      &-slide {
        @include background(
          url('#{$path-events}shared/milestones/progress-bar-fill--glowless.avif'),
          contain,
          center,
          repeat
        );
        height: 1.875rem;
        left: 0;
        position: absolute;
        top: 52.5%;
        transform: translate(0, -52%);
        -webkit-filter: drop-shadow(0px 0px 5px rgb(249 227 152));
        filter: drop-shadow(0px 0px 5px rgb(249 227 152));

        &--actual-progress {
          &::after {
            content: '';
            position: absolute;
            right: -1.188rem;
            top: 0;
            @include background(
              url('#{$path-events}shared/milestones/progress-bar-fill-end--glowless.avif'),
              contain,
              left center
            );
            height: 100%;
            width: 1.25rem;
          }
        }
      }
    }
  }

  .club-lock-overlay {
    background-color: #030e03;
    opacity: 0.93;
  }

  .club-lock {
    @include background(
      url('#{$path-events}shared/milestones/club-lock-middle.avif'),
      contain,
      center,
      repeat
    );
    height: 14.75rem;

    &-text {
      width: 35.0625rem;
      text-align: left;
      line-height: 1.2;
    }
  }
}
</style>
