import type { ImageLoadData } from '@/map-phaser-new/interfaces'

type EventsShipImageLoadDataFunction = (eventAssetsDirectory: string) => ImageLoadData[]

export const eventsShipImageLoadData: EventsShipImageLoadDataFunction = (
  eventAssetsDirectory: string,
): ImageLoadData[] => [
  {
    name: 'buildings.event_ship',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: `events/${eventAssetsDirectory}/event-ship/event-ship-astc12x12.ktx`,
      },
      etc: {
        type: 'KTX',
        textureURL: `events/${eventAssetsDirectory}/event-ship/event-ship-etc2rgba.ktx`,
      },
      img: {
        textureURL: `events/${eventAssetsDirectory}/event-ship/event-ship-png8.avif`,
      },
      place: `events/${eventAssetsDirectory}/event-ship/event-ship-png8.avif`,
    },
  },
  {
    name: 'buildings.event_ship_outline',
    compressed: true,
    data: {
      astc: {
        type: 'KTX',
        textureURL: `events/${eventAssetsDirectory}/event-ship-outline/event-ship-outline-astc12x12.ktx`,
      },
      etc: {
        type: 'KTX',
        textureURL: `events/${eventAssetsDirectory}/event-ship-outline/event-ship-outline-etc2rgba.ktx`,
      },
      img: {
        textureURL: `events/${eventAssetsDirectory}/event-ship-outline/event-ship-outline-png8.png`,
      },
      place: `events/${eventAssetsDirectory}/event-ship-outline/event-ship-outline-png8.png`,
    },
  },
]
