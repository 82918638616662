<template>
  <div
    class="app-user flex items-center"
    :class="!isBot && id ? 'cursor-pointer' : ''"
    @click.stop="showUserProfile"
  >
    <p
      v-if="!noCountryText && country"
      class="app-user-country text-24 uppercase"
      :class="{
        'font-bold': $isWsm,
        'text-texts-standard-dark': $isWsm && isTableMeRow,
        'text-texts-standard-name': $isWsm && !isTableMeRow,
        'text-texts-standard-additional': $isSsm,
      }"
    >
      {{ country }}
    </p>
    <div
      v-if="country"
      class="app-user-flag"
      :class="[flagClass]"
      :style="{ backgroundImage: 'url(' + pathImages + 'flags/102x68/' + country + '.avif)' }"
    />
    <section class="ml-4">
      <p
        class="app-user-name text-32"
        :class="[
          isTableMeRow || darkText ? 'text-texts-standard-dark' : 'text-texts-standard-default',
          { 'font-bold': boldName },
          userClass,
        ]"
        :style="'maxWidth:' + rowMaxHeight"
      >
        {{ name }}
      </p>
      <aside v-if="role" class="app-user-role text-28">
        <span v-if="role === 'manager'" class="app-user-role-manager">
          <app-icon icon-name="manager-star" class="mr-2" />
          <p class="text-texts-standard-important">
            {{ $t('club.' + role) }}
          </p>
        </span>
        <span v-else-if="role === 'assistant'">
          <p class="text-assistant">{{ $t('club.' + role) }}</p>
        </span>
        <span v-else>
          <p :class="$isWsm ? 'text-texts-standard-name' : 'text-texts-standard-additional'">
            {{ $t('club.' + role) }}
          </p>
        </span>
      </aside>
    </section>
    <span v-if="badgesData" class="badge-wrapper flexing" :class="{ 'has-role': role }">
      <event-badge :current-badges="badgesData" class="ml-2" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState } from 'pinia'
import EventBadge from '@/components/GlobalComponents/EventBadge.vue'
import { pathImages } from '@/globalVariables'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type { EventBadgeInterface } from '@/interfaces/EventBadgeInterface'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'AppUser',

  components: {
    EventBadge,
  },

  props: {
    flagClass: {
      type: String,
      default: '',
    },
    userClass: {
      type: String,
      default: '',
    },
    country: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    isBot: {
      type: Boolean,
      default: false,
    },
    isTableMeRow: {
      type: Boolean,
      default: false,
    },
    noCountryText: {
      type: Boolean,
      default: false,
    },
    darkText: {
      type: Boolean,
      default: false,
    },
    boldName: {
      type: Boolean,
      default: false,
    },
    role: {
      type: String,
      default: '',
    },
    rowMaxHeight: {
      type: String,
      default: '',
    },
    badgesData: {
      type: Array as PropType<Nullable<EventBadgeInterface[]>>,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useDisciplineStore, {
      getUnlockedDisciplinesIds: 'getUnlockedDisciplinesIds',
    }),
  },
  methods: {
    showUserProfile(): void {
      if (!this.isBot && this.id) {
        this.$router.push({
          name: this.$getWebView('ProfileOverview'),
          params: { id: this.getUnlockedDisciplinesIds[0], user: this.id },
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.app-user {
  &-country {
    width: 3.75rem;
    margin: 1.8rem 0 0.875rem 0;
  }

  &-flag {
    background-repeat: no-repeat;
    background-size: contain;
    width: 3.15rem;
    height: 2.2rem;
    box-shadow: 0.1px 2px 2px 0 rgba(14, 14, 14, 0.56);
  }

  &-name {
    text-shadow: 0.643px 0.766px 0.93px rgba(0, 0, 0, 0.71);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.text-texts-standard-dark {
      text-shadow: none;
    }
  }

  &-role {
    line-height: 2.5rem;
    text-align: left;

    &-manager {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
    }

    .text-assistant {
      color: #ffa200;
      text-shadow: 0.6px 0.8px 0.9px rgba(0, 0, 0, 0.71);
    }
  }
}

// Ked zacneme pouzivat mode: 'jit', tak toto moze ist doprec.
// https://v2.tailwindcss.com/docs/just-in-time-mode
.\!w-7 {
  width: 1.75rem !important;
}

.\!w-8 {
  width: 2rem !important;
}

.\!w-9 {
  width: 2.25rem !important;
}

.\!h-5 {
  height: 1.25rem !important;
}

.\!h-6 {
  height: 1.5rem !important;
}

.\!h-7 {
  height: 1.75rem !important;
}

.\!text-28 {
  font-size: 1.75rem !important;
}

.\!text-32 {
  font-size: 1.75rem !important;
}

.\!text-36 {
  font-size: 2.25rem !important;
}

.badge-wrapper {
  &.has-role {
    position: relative;
    top: -1rem;
  }
}
</style>
