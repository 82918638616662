<template>
  <div
    class="reward-box flexing flex-col special"
    :class="[
      `mx-${rewardMarginSize}`,
      {
        'reward-box--highlighted': highlightedReward,
        'reward-box--special': isSpecial,
        'reward-box--event-reputation': isEventReputationReward,
        [`reward-box--event-offer-box-${eventOfferBoxType}`]: !!eventOfferBoxType,
        'not-claimable': !isClaimable,
        relative: isDisabled,
      },
      equipSpecialClass,
    ]"
  >
    <tippy
      v-if="isDisabled"
      theme="WSM1"
      placement="top"
      class="reward-box-disabled-overlay absolute top-0 left-0 w-full h-full z-1"
    >
      <template #content>
        <div v-if="rewardType === EVENT_POINTS" class="lock-tooltip">
          <p class="lock-tooltip-title flexing text-30 font-bold uppercase">
            {{ $te('eventPoints') }}
          </p>
          <p class="lock-tooltip-text-margin flexing text-28 text-texts-standard-danger italic">
            {{ $te('unclaimableRewardEventEnded') }}
          </p>
        </div>
      </template>
    </tippy>
    <section v-if="equipmentReward && !isEventEquip" class="flexing stars-container">
      <div v-for="i in 3" :key="i" class="icon-star-full-24" />
    </section>
    <div
      v-if="equipmentReward"
      v-tippy="{
        theme: 'WSM',
        content: $t('equipmentShop.premiumEquipment'),
        placement: 'top',
      }"
      class="equipment-reward"
      :class="[itemClass, { 'equipment-reward--event': isEventEquip }]"
    />
    <avatar-box
      v-else-if="avatarReward"
      v-tippy="{
        theme: 'WSM',
        content: isAvatarBackgroundOnly
          ? $t('premiumGroup.avatarBackground')
          : isAvatarOnly
            ? $t('premiumGroup.avatar')
            : $t('premiumGroup.animatedAvatar'),
        placement: 'top',
      }"
      class="reward-avatar"
      :is-avatar="isAvatarOnly"
      :avatar-id="isAvatarBackgroundOnly ? null : rewardData?.value"
      :is-background="isAvatarBackgroundOnly"
      :background-id="isAvatarBackgroundOnly ? rewardData?.value : null"
      :avatar-gender="sex"
      :custom-width="6"
      :custom-height="6"
    />

    <app-icon
      v-else-if="rewardData?.eventCurrency"
      class="icon-in-rewards mx-auto"
      :icon-name="rewardData?.icon ?? rewardData?.type"
      :tooltip-text="$t(`event.${rewardIconName}`)"
    />

    <app-icon v-else-if="rewardIconName === 'no-ads'" class="mx-auto" :icon-name="rewardIconName" />

    <tippy v-else-if="!isClaimable" theme="WSM">
      <app-main-icon
        :hide-tooltip="true"
        class="mx-auto"
        :icon-name="formattedName"
        :icon-size="48"
      />
      <template #content>
        <div class="special-case">
          <div class="head" style="margin-bottom: 1rem">{{ $t('event.eventPoints') }}</div>
          <div class="red" style="color: red">
            {{ $te('unclaimableRewardEventEnded') }}
          </div>
        </div>
      </template>
    </tippy>
    <app-main-icon v-else class="mx-auto" :icon-name="formattedName" :icon-size="rewardIconSize" />
    <div v-if="!isSpecial" class="flexing">
      <p
        v-tippy="{
          theme: 'WSM',
          content:
            isIconInNameAllowed || forcePercentage ? '' : $filters.$formatNumber(rewardNumber),
          placement: 'top',
        }"
        class="reward-box-text text-texts-standard-default"
        :class="[
          `text-${fontSize}`,
          { 'text-texts-standard-important': isDoubleRewardActive },
          getTextMargin,
        ]"
      >
        {{ checkRewardNumber }}
      </p>
      <app-main-icon
        v-if="isIconInNameAllowed"
        class="text-icon"
        :class="getTextMargin"
        :icon-name="iconInName[rewardIconName]"
        :icon-size="32"
      />
    </div>

    <app-icon
      v-if="isDoubleRewardActive"
      :icon-name="'arrow-up-gold'"
      class="double-reward--active wave-animation wave-animation--slow"
    />

    <app-svg-icon v-if="hasSvgBackground" icon-name="rewardBox" class="reward-box__background" />
  </div>
</template>

<script lang="ts">
import AvatarBox from '@/components/Profile/AvatarBox.vue'
import {
  AVATARS,
  AVATAR_BGS,
  AVATAR_ITEM,
  ENERGY,
  ENERGY_DRINK,
  EVENT_POINTS,
  EVENT_REPUTATION,
  FONT_SIZES,
  MECHANIC_BENEFIT,
  PREMIUM_EQUIPMENT_ITEM,
  REWARD_AVATAR_TYPES,
  STARTS,
  WILD_CARD,
} from '@/globalVariables'
import { setMultiplierText } from '@/helpers'
import { defineComponent } from 'vue'

import type { PropType } from 'vue'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'

// only in one case we have special "rewardData" from BE - equipment reward
interface RewardBoxData extends Reward {
  rewardData?: {
    discipline_id: string
    gdd_id: string
  }
}

interface ComponentData {
  EVENT_POINTS: typeof EVENT_POINTS
  iconInName: Record<string, string>
}

export default defineComponent({
  name: 'RewardBox',
  components: {
    AvatarBox,
  },
  props: {
    isClaimable: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    highlightedReward: {
      type: Boolean,
      default: false,
    },
    forcePercentage: {
      type: Boolean,
      default: false,
    },
    rewardType: {
      type: String,
      default: '',
    },
    rewardNumber: {
      type: [Number, String],
      default: null,
    },
    rewardMarginSize: {
      type: Number,
      default: 3,
    },
    rewardIconSize: {
      type: Number,
      default: 48,
    },
    fontSize: {
      type: Number,
      default: 28,
      validator: (value: number): boolean => FONT_SIZES.includes(value),
    },
    rewardIconName: {
      type: String,
      default: null,
    },
    rewardIconRarity: {
      type: String,
      default: null,
    },
    isPass: {
      type: Boolean,
      default: false,
    },
    rewardData: {
      type: Object as PropType<Nullable<RewardBoxData>>,
      default: () => null,
    },
    sex: {
      type: String,
      default: null,
    },
    isDoubleRewardActive: {
      type: Boolean,
      default: false,
    },
    eventOfferBoxType: {
      type: String,
      default: '',
    },
  },
  data(): ComponentData {
    return {
      EVENT_POINTS,
      iconInName: {
        [WILD_CARD]: STARTS,
        [ENERGY_DRINK]: ENERGY,
      },
    }
  },
  computed: {
    formattedName(): string {
      if (this.rewardIconName === 'grand_prize') return 'gp-' + this.rewardIconRarity
      else return this.rewardIconName
    },
    checkRewardNumber(): string {
      const reward = this.rewardIconName?.includes(MECHANIC_BENEFIT)
        ? MECHANIC_BENEFIT
        : this.rewardIconName

      if (this.isIconInNameAllowed) {
        return `+${this.$filters.$formatNumber(Number(this.rewardNumber))}`
      }

      if (this.forcePercentage) {
        return `+${this.$filters.$formatNumber(Number(this.rewardNumber))}%`
      }
      return this.rewardNumber === null
        ? ''
        : this.$filters.$abbrRewardValue(Number(this.rewardNumber)) + setMultiplierText(reward)
    },
    isIconInNameAllowed(): boolean {
      return this.isPass && [ENERGY_DRINK, WILD_CARD].includes(this.rewardIconName)
    },
    isSpecial(): boolean {
      return this.equipmentReward || this.avatarReward
    },
    equipmentReward() {
      return this.rewardData?.name === PREMIUM_EQUIPMENT_ITEM
    },
    isEventEquip(): boolean {
      return this.equipmentReward && this.rewardData.rewardData.gdd_id === '6'
    },
    avatarReward(): boolean {
      return REWARD_AVATAR_TYPES.includes(this.rewardData?.name)
    },
    equipSpecialClass(): string {
      if (this.equipmentReward || this.avatarReward) {
        if (this.isEventEquip) {
          return 'reward-box--special-event'
        }
        return 'reward-box--special'
      }
      return ''
    },
    isAvatarBackgroundOnly(): boolean {
      return this.rewardData?.name === AVATAR_BGS
    },
    isAvatarOnly(): boolean {
      return this.rewardData?.name === AVATARS || this.rewardData?.name === AVATAR_ITEM
    },
    itemClass(): string {
      return `item-${this.rewardData?.rewardData?.discipline_id}-${this.rewardData?.rewardData?.gdd_id}-1`
    },
    isEventReputationReward(): boolean {
      return this.rewardData?.name === EVENT_REPUTATION
    },
    getTextMargin(): string {
      switch (this.rewardIconSize) {
        case 80:
          return '!m-0'
        case 72:
          return 'mt-2'
        default:
          return 'mt-3'
      }
    },
    hasSvgBackground(): boolean {
      return (
        !this.highlightedReward &&
        !this.isSpecial &&
        !this.isEventReputationReward &&
        !this.eventOfferBoxType
      )
    },
  },
  methods: {
    setMultiplierText,
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/components/icons/stars-icons.scss';
@import '@/assets/styles/animations.scss';

.reward-box {
  width: 6.938rem;
  height: 8.5rem;
  position: relative;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  & *:not(.reward-box__background) {
    z-index: 1;
  }

  &.not-claimable {
    background-color: rgba(120, 41, 51, 1);
    box-shadow: unset;
  }

  &--highlighted {
    background: url($path-images + 'components/rewardBox/reward-box-gold.avif') center no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }
  }

  &--special {
    background: url($path-images + 'components/rewardBox/reward-box-special.avif') center no-repeat;
  }

  &--special-event {
    background: url($path-images + 'components/rewardBox/reward-box-special-event.avif') center
      no-repeat;
  }

  &--special,
  &--special-event {
    background-size: contain;
    border: none;
    box-shadow: none;

    &:before,
    &:after {
      display: none;
    }
  }

  &--event-reputation {
    background-image: url('#{$path-events}discipline-event/event-reward-box.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    box-shadow: none;

    &::before,
    &::after {
      display: none;
    }
  }

  &--event-offer-box-basic,
  &--event-offer-box-premium,
  &--event-offer-box-1,
  &--event-offer-box-2,
  &--event-offer-box-3 {
    @include background(null, contain);
    border: none;
    box-shadow: none;

    &::before,
    &::after {
      display: none;
    }
  }

  // wsm
  &--event-offer-box-basic {
    background-image: url('#{$path-events}discipline-event/offers/small-offer-event-reward-box-basic.webp');
  }

  // wsm
  &--event-offer-box-premium {
    background-image: url('#{$path-events}discipline-event/offers/small-offer-event-reward-box-premium.webp');
  }

  // ssm
  &--event-offer-box-1 {
    background-image: url('#{$path-events}discipline-event/offers/small-offer-event-reward-box-1.webp');
  }

  // ssm
  &--event-offer-box-2 {
    background-image: url('#{$path-events}discipline-event/offers/small-offer-event-reward-box-2.webp');
  }

  // ssm
  &--event-offer-box-3 {
    background-image: url('#{$path-events}discipline-event/offers/small-offer-event-reward-box-3.webp');
  }

  .reward-box-disabled-overlay {
    background-color: rgba(205, 20, 20, 0.5);
    border-bottom-right-radius: 0.875rem;
  }

  .reward-box-text {
    text-transform: lowercase;
  }

  .equipment-reward {
    background-repeat: no-repeat;
    background-size: contain;
    width: 6.938rem;
    height: 5.625rem;
    position: absolute;
    bottom: 1rem;

    @for $discipline from 1 through 14 {
      &.item-#{$discipline}-5-1 {
        background-image: url($path-equipment + 'items/rewards/' + #{$discipline} + '/5-1.avif');
      }

      &.item-#{$discipline}-6-1 {
        background-image: url($path-equipment + 'items/rewards/' + #{$discipline} + '/6-1.avif');
      }
    }

    &--event {
      bottom: auto;
    }
  }

  .stars-container {
    position: absolute;
    top: 0.4rem;
  }

  .double-reward {
    &--active {
      position: absolute;
      top: -0.6rem;
      right: -0.9rem;
    }
  }
}
</style>
