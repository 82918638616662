<template>
  <main v-if="loaded" class="flexing flex-col w-full h-full">
    <session-modal v-if="sessionModalRewards" :rewards="sessionModalRewards" />
    <event-message v-else-if="eventCollectionActive" :event-ended="true" />
    <chains-main v-else-if="!currentChainId" />
    <teams-main v-else-if="!currentTeamId" />
    <tasks-main v-else />
  </main>
  <component-loading v-else :is-loading="true" height="56.75rem" />
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useTaskChainStore } from '@/store/pinia/events/taskChainStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventMessage from '../EventMessage.vue'
import ChainsMain from './ChainsComponents/ChainsMain.vue'
import SessionModal from './SessionModal.vue'
import TasksMain from './TasksComponents/TasksMain.vue'
import TeamsMain from './TeamsComponents/TeamsMain.vue'

interface ComponentData {
  loaded: boolean
}

export default defineComponent({
  name: 'TaskchainMain',
  components: {
    ChainsMain,
    TeamsMain,
    TasksMain,
    SessionModal,
    EventMessage,
  },
  data(): ComponentData {
    return {
      loaded: false,
    }
  },
  computed: {
    ...mapState(useTaskChainStore, {
      currentChainId: 'getCurrentChainId',
      currentTeamId: 'getCurrentTeamId',
      sessionModalRewards: 'getSessionModalRewards',
    }),
    ...mapState(useEventInfoStore, {
      eventCollectionActive: 'getEventCollectionActive',
    }),
  },
  async created(): Promise<void> {
    await Promise.all([this.loadConfig(), this.loadState()])
    this.loaded = true
  },
  methods: {
    ...mapActions(useTaskChainStore, {
      loadConfig: 'loadConfig',
      loadState: 'loadState',
    }),
  },
})
</script>
