<template>
  <div class="lock-tooltip">
    <div class="lock-tooltip-title flexing">
      <p class="uppercase font-medium text-30">
        {{ $t('gamePass.multiplier') }}
        <span class="text-texts-standard-important"> {{ `${state.value}x` }} </span>
      </p>
    </div>
    <div
      class="lock-tooltip-info px-12 py-6"
      v-html="
        state.quest_gdd_id
          ? $replacePlaceholder(tooltipConfig[index], '{quest_number}', state.quest_gdd_id)
          : tooltipConfig[index]
      "
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

import type { MultiplicatorItem } from '@/types/userProfile'

interface ComponentData {
  tooltipConfig: Record<number, string>
}

export default defineComponent({
  name: 'SuperMultiplierTooltip',
  props: {
    state: {
      type: Object as PropType<MultiplicatorItem>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      tooltipConfig: {
        // (i) Tooltips will only be possible for 2-4 states
        1: this.$replacePlaceholder(
          this.$t('common.mainCareerTaskRequired'),
          '%s',
          '<span class="text-texts-standard-important">{quest_number}</span>',
        ),
        2: this.$replacePlaceholder(
          this.$t('gamePass.availableInPassPro'),
          '{passpro}',
          `<span class="text-texts-standard-important">${this.$t('gamePass.gamePassPro')}</span>`,
        ),
        3: this.$replacePlaceholder(
          this.$t('gamePass.availableInPassElite'),
          '{passelite}',
          `<span class="text-texts-standard-important">${this.$t('gamePass.gamePassElite')}</span>`,
        ),
      },
    }
  },
})
</script>

<style lang="scss" scoped></style>
