<template>
  <section
    class="header w-full safe-area-x"
    :class="{
      [`discipline-${eventDisciplineId}`]: !!eventDisciplineId,
      'header--desktop': !$isMobile(),
    }"
    :data-event-theme="namespace"
  >
    <events-title />
    <routing-section :views="views" />
  </section>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'

import EventsTitle from './EventsTitle.vue'
import RoutingSection from './RoutingSection/RoutingSection.vue'
import type { EventViews } from '@/interfaces/events/EventViews'

export default defineComponent({
  name: 'EventsHeader',
  components: {
    EventsTitle,
    RoutingSection,
  },
  props: {
    views: {
      type: Object as PropType<EventViews>,
      required: true,
    },
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventDisciplineId: 'getEventDisciplineId',
    }),
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

[data-event-theme='challenge_cup'] {
  --event-header-background: url('#{$path-events}challenge-cup/event-header.avif');
}

[data-event-theme='frozen'] {
  --event-header-background: url('#{$path-events}frozen-championship/event-header.avif');
}

[data-event-theme='discipline'] {
  @for $i from 1 through 20 {
    --event-header-background-#{$i}: url('#{$path-events}discipline-event/event-header-discipline-#{$i}.webp');
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5.4375rem;
  @include background(var(--event-header-background), cover);

  &--desktop {
    padding-left: $default-margin + 0.6rem;
    padding-right: $default-margin + 0.6rem;
  }

  @if $isWsm {
    background-color: #143157;
  }

  @if $isSsm {
    background-color: rgba(45, 56, 83, 0.6);
  }

  @for $i from 1 through 20 {
    &.discipline-#{$i} {
      background-image: var(--event-header-background-#{$i});
      background-size: contain;
      background-position: left;
    }
  }
}
</style>
