<template>
  <div class="title cursor-pointer relative">
    <img
      v-if="namespace === 'frozen' || namespace === 'challenge_cup'"
      class="title-logo h-full"
      :src="`${pathImages}events/${eventAssetsDirectory}/event-logo.avif`"
      :class="{ 'pointer-events-none': eventCollectionActive }"
      @click="onClick"
    />
    <app-icon
      v-if="!eventCollectionActive && (namespace === 'frozen' || namespace === 'challenge_cup')"
      icon-name="info-rounded"
      class="title-icon pointer-events-none absolute top-0 -right-4"
    />
  </div>
</template>

<script lang="ts">
import { pathImages } from '@/globalVariables'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'EventsTitle',
  data(): ComponentData {
    return {
      pathImages,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      eventAssetsDirectory: 'getEventAssetsDirectory',
      eventCollectionActive: 'getEventCollectionActive',
      namespace: 'getEventType',
    }),
  },
  methods: {
    onClick(): void {
      if (this.eventCollectionActive) return
      this.$router.push({ name: this.$getWebView('EventsTeaser') })
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

.title {
  height: 6.125rem;

  &:active {
    transform: scale(0.95);
  }

  &-icon {
    transform: $scale-default;
  }
}
</style>
