<template>
  <header
    class="passes-subheader flexing w-full mb-2 relative"
    :class="{ 'is-premium': $route.meta.isPremiumRoute }"
    :data-event-theme="namespace"
  >
    <p
      class="flexing gradient-text-light font-bold uppercase"
      :class="$route.meta.isPremiumRoute ? 'text-50' : 'text-60'"
    >
      {{ $te(`${$route.query?.pass}pass`) }}
    </p>
    <passes-info-element
      v-if="
        $route.meta.isPremiumRoute &&
        !eventCollectionActive &&
        $route.query?.pass === PassType.Event
      "
      class="absolute top-6 right-8"
    />
  </header>
</template>

<script lang="ts">
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import PassesInfoElement from './PassesInfoElement.vue'
interface ComponentData {
  PassType: typeof PassType
}

export default defineComponent({
  name: 'PassesSubHeader',
  components: { PassesInfoElement },
  data(): ComponentData {
    return {
      PassType,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCollectionActive: 'getEventCollectionActive',
    }),
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-passes-header-bg: url('#{$path-events}challenge-cup/passes/event-pass-header-bg.webp?v=2');
}

[data-event-theme='frozen'] {
  --events-passes-header-bg: url('#{$path-events}frozen-championship/passes/event-pass-header-bg.webp');
}

[data-event-theme='discipline'] {
  --events-passes-header-bg: url('#{$path-events}discipline-event/passes/master-pass-header-bg.webp');
}

.passes-subheader {
  height: 4.5rem;
  @include background(var(--events-passes-header-bg), cover);

  &.is-premium {
    height: 5.5rem;
    background-image: none;
  }
}
</style>
