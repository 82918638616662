<template>
  <td
    class="h-full"
    :style="{
      width: `${column.width}%`,
    }"
  >
    <div
      class="cell-container w-full h-full"
      :style="{
        background: column.background,
        justifyContent: flexContentPosition,
      }"
      :class="{ 'background-skew': column.isBackgroundSkew }"
    >
      <div v-if="column.key === clubTableKeys.button">
        <tippy
          v-if="!afterButtonClick || !clickedElement"
          theme="WSM"
          placement="top"
          :on-show="setShowTooltip"
        >
          <app-button
            :add-class="column.buttonData?.class"
            :btn-size="column.buttonData?.size"
            :btn-type="column.buttonData?.type"
            :btn-text="$t(column.buttonData?.text)"
            class="cell-button"
            :disabled="column.buttonData?.disabled ? true : false"
            @click="buttonClickEvent(selectCorrectId)"
          />
          <template #content>
            <div
              v-if="column.buttonData?.disabled?.type === 'quarantine'"
              class="flexing items-center flex-col"
            >
              <p>
                {{ $replacePlaceholder($t('club.clubCooldown'), '%s', 24) }}
              </p>
              <p>{{ $t('common.timeRemaining') }}</p>
              <p class="flex">
                <app-icon class="tooltip-text-icon-time mr-2" :icon-size="32" :icon-name="'time'" />
                <vue-countdown
                  v-slot="{ days, hours, minutes, seconds }"
                  :time="column.buttonData?.disabled.content * 1000"
                  :auto-start="true"
                >
                  {{ formatTime(days, hours, minutes, seconds) }}
                </vue-countdown>
              </p>
            </div>
            <p v-else-if="column.buttonData?.disabled">
              {{ $t('club.noVacancy') }}
            </p>
          </template>
        </tippy>
        <p v-if="afterButtonClick && clickedElement" class="text-36 font-bold uppercase">
          {{ $t(column.text ? column.text : 'club.applicationAccepted') }}
        </p>
      </div>

      <div v-else-if="column.key === clubTableKeys.commodityPoints" class="flexing">
        <p>{{ item.commodityPoints.value }}</p>
        <app-icon
          :icon-name="item.commodityPoints.icon"
          :tooltip-text="$t(`event.${item.commodityPoints.name}`)"
          class="commodity-points-size"
        />
      </div>

      <div v-else-if="column.key === clubTableKeys.reputation" class="flexing">
        <p class="mr-4">{{ $filters.$formatNumber(item.points) }}</p>
        <app-icon icon-name="reputation" :tooltip-text="$t(`event.reputation`)" />
      </div>

      <div v-else-if="column.key === clubTableKeys.progress">
        <div
          v-if="item?.progress?.advancing || item?.progress?.descending"
          class="triangle"
          :class="[item?.progress?.advancing ? 'green triangle-up' : 'red triangle-down']"
        />
        <p v-else class="text-50">-</p>
      </div>

      <rewards
        v-else-if="column.key === clubTableKeys.rewards"
        :class="{ 'revert-skew': column.isBackgroundSkew }"
        :reward-data="item.rewards || []"
        :is-font-bold="false"
        :icon-size="32"
      />

      <player-level
        v-else-if="column.key === clubTableKeys.level"
        class="cell-level"
        :level="item[clubTableKeys.level]"
      />

      <!-- skryt ak je afterButtonClick true -->
      <app-control-icon
        v-else-if="
          column.key === clubTableKeys.delete &&
          item.name.role !== 'assistant' &&
          item.name.role !== 'manager'
        "
        control="delete"
        @click="$emit('deleteCell', selectCorrectId)"
      />

      <app-hamburger-icon
        v-else-if="column.key === clubTableKeys.settings && item.name.role !== 'manager'"
        icon-name="admin"
        @click="openMemberSettings"
      />

      <div
        v-else-if="column.key === clubTableKeys.members"
        :class="
          item.clubCapacity <= item.membersCount
            ? 'text-texts-standard-danger font-bold'
            : 'text-texts-standard-default'
        "
      >
        <p>{{ `${item.membersCount}/${item.clubCapacity}` }}</p>
      </div>

      <div
        v-else-if="column.key === clubTableKeys.pos"
        class="cell-pos-and-online flexing w-full h-full"
        :style="{
          background: column.coloredPos ? setPositionBackground(item?.pos) : undefined,
          clipPath: column.coloredPos && 'polygon(0% 0%,100% 0%,90% 100%,0% 100%)',
        }"
      >
        <p
          class="text-36 font-bold"
          :class="
            column.coloredPos && (item.pos <= 3 || $isSsm)
              ? 'text-texts-standard-dark'
              : 'text-texts-standard-default'
          "
        >
          {{ item.pos }}
        </p>
      </div>

      <div v-else-if="column.key === clubTableKeys.rank">
        <div
          v-if="item.rank <= 3"
          class="rank-styles text-texts-standard-dark text-36 font-bold flexing"
          :style="{ background: setPositionBackground(item.rank) }"
        >
          {{ item.rank }}
        </div>
        <p v-else class="rank-styles-lower text-texts-standard-default text-36 font-bold">
          {{ item.rank }}
        </p>
      </div>

      <div
        v-else-if="column.key === clubTableKeys.onlineStatus"
        v-tippy="{
          content: replacePlaceholder($t('club.' + item.onlineStatus), '%s', item.offlineThreshold),
          theme: 'WSM',
        }"
        class="online-status"
        :class="'is-' + item.onlineStatus"
      />

      <app-user
        v-else-if="
          column.key === clubTableKeys.nameComponent || column.key === clubTableKeys.username
        "
        :id="column.key === clubTableKeys.nameComponent ? item.userId : item.userId"
        :name="column.key === clubTableKeys.nameComponent ? item.name?.name : item.name"
        :country="column.key === clubTableKeys.nameComponent ? item.name?.country : ''"
        :role="column.key === clubTableKeys.nameComponent ? item.name?.role : ''"
        :badges-data="item.eventBadges ?? null"
        class="club-chat-cell-message-player"
      />

      <app-club-name
        v-else-if="column.key === clubTableKeys.clubNameComponent"
        :club-id="item.clubId"
        :name="item.name"
        :club-logo-id="item.logoId"
        :clubs-background-id="item.backgroundId"
        :class="{ flexing: column.align === 'center' }"
      />

      <div v-else>
        <div v-if="column.key === clubTableKeys.country" class="flex items-center">
          <flag-component :country="item[clubTableKeys.country]" />
        </div>
        <div v-else-if="column.key === clubTableKeys.name" class="cell-name italic">
          <div v-if="column.avatar" class="cell-avatar">
            <clubs-avatar
              :logo-id="Number(item.logoId)"
              :background-id="Number(item.backgroundId)"
            />
          </div>
          {{ item[column.key] }}
        </div>

        <span v-else class="italic">
          {{ formatNumbers(item[column.key]) }}
        </span>
      </div>
    </div>
  </td>
</template>

<script lang="ts">
import ClubsAvatar from '@/components/Club/ClubsAvatar.vue'
import FlagComponent from '@/components/FlagComponent.vue'
import AppClubName from '@/components/GlobalComponents/AppClubName.vue'
import AppHamburgerIcon from '@/components/GlobalComponents/AppHamburgerIcon.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import PlayerLevel from '@/components/Profile/PlayerLevel.vue'
import Rewards from '@/components/Rewards.vue'
import { clubTableKeys } from '@/components/Table/tableKeys'
import { CHAMPIONSHIP_POINTS, PRESTIGE } from '@/globalVariables'
import { formatTime } from '@/helpers'
import { replacePlaceholder } from '@/plugins'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableCell',
  components: {
    FlagComponent,
    PlayerLevel,
    ClubsAvatar,
    AppUser,
    AppClubName,
    AppHamburgerIcon,
    Rewards,
  },

  props: {
    item: {
      type: Object,
      default: null,
    },
    column: {
      type: Object,
      default: null,
    },
    whichId: {
      type: String,
      default: 'id',
    },
  },
  emits: ['buttonClick', 'deleteCell', 'memberSettings'],
  data() {
    return {
      clubTableKeys,
      afterButtonClick: false,
      afterButtonClickId: '',
      clubFreePlaces: 0,
    }
  },
  computed: {
    selectCorrectId() {
      if (this.whichId === 'id') return this.item.id
      if (this.whichId === 'userId') {
        return this.item.user_id ?? this.item.userId
      }
      return this.item.clubId
    },
    clickedElement() {
      return this.selectCorrectId === this.afterButtonClickId
    },
    flexContentPosition(): string {
      // flex-start, flex-end, center
      if (this.column.align === 'left') return 'flex-start'
      if (this.column.align === 'right') return 'flex-end'
      return 'center'
    },
  },
  methods: {
    replacePlaceholder,
    formatTime,
    setShowTooltip() {
      // treba kvoli funkcii na on-show pre tippy
      if (this.column.buttonData?.disabled) return true
      return false
    },
    formatNumbers(value) {
      if (typeof value !== 'number') return value

      if (this.column.label === PRESTIGE || this.column.label === CHAMPIONSHIP_POINTS) {
        return this.$filters.$formatNumber(value)
      }

      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    buttonClickEvent(id) {
      if (!this.column.buttonData?.disabled) {
        this.$emit('buttonClick', id)
        if (this.column.buttonData.afterClick) {
          this.afterButtonClick = true
          this.afterButtonClickId = id
        }
      }
    },
    deleteRow(item) {
      const id = (item.user_id ?? item.userId) ? (item.user_id ?? item.userId) : item.id
      this.$emit('deleteCell', id)
    },
    openMemberSettings() {
      this.$emit('memberSettings', this.item.userId)
    },
    setPositionBackground(position) {
      switch (position) {
        case this.$isWsm && 1:
          return 'linear-gradient(to top, #ffef84, #f6c717)'
        case this.$isSsm && 1:
          return 'linear-gradient(to right, #daab36, #ffdf91)'

        case this.$isWsm && 2:
          return 'linear-gradient(to top, #a4a4a4, #fff)'
        case this.$isSsm && 2:
          return 'linear-gradient(to right, #8e8e8e, #dbdbdb)'

        case this.$isWsm && 3:
          return 'linear-gradient(to top, #e77447, #ffb99e)'
        case this.$isSsm && 3:
          return 'linear-gradient(to right, #f58d57, #ffb28a)'

        default: {
          if (this.$isWsm) {
            return '#154c76'
          }
          if (this.$isSsm) {
            return '#fff'
          }
        }
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.cell-container {
  display: flex;
  align-items: center;
}

.cell-button {
  // universal offset of btn due to required positioning to right
  margin: 0 0.5rem;
}

.cell-level {
  margin: 0 auto;
}

.cell-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.cell-avatar {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
}

.cell-narrower-button {
  width: 16.875rem;
}

.online-status {
  margin: 0 auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;

  &.is-online {
    border: solid 0.125rem #0ac506;
    background-image: radial-gradient(circle at 0 0, #3fe443, #18c110);
    box-shadow:
      0 0 13.5px 1.5px rgb(63 228 67 / 30%),
      inset 0px 5px 5px 0 rgb(63 228 67 / 50%);
  }

  &.is-offline {
    border: solid 0.125rem #ffa200;
    background-image: radial-gradient(circle at 0 0, #eb9600, #fdb333);
    box-shadow:
      0 0 13.5px 1.5px rgba(255, 162, 0, 0.3),
      inset 0px 5px 5px 0 rgba(255, 162, 0, 0.5);
  }

  &.is-offlineMoreThan {
    border: solid 0.125rem #4f4f4f;
    background-image: radial-gradient(circle at 0 0, #505050, #515151);
    box-shadow:
      0 0 13.5px 1.5px rgba(102, 102, 102, 0.3),
      inset 0px 5px 5px 0 rgba(53, 53, 53, 0.5);
  }
}

.commodity-points-size {
  width: 2rem !important;
  height: 2rem !important;
}

.background-skew {
  transform: $skewX-value;
}

.rank-styles {
  width: 6.438rem;
  height: 5rem;
  margin-left: -2rem;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
}

.rank-styles-lower {
  padding-left: 0.2rem;
}
</style>
