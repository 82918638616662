<template>
  <tippy
    class="tournament-box w-full h-full flexing text-36 text-texts-standard-default font-bold whitespace-nowrap space-x-4"
    :class="[{ 'is-wide': resources.length > 1 }, $isSsm ? 'revert-skew' : 'skew']"
    theme="WSM"
    placement="bottom"
    max-width="50rem"
  >
    <template v-if="resources.length === 1 && resources[0].icon === MULLIGANS" #content>
      <div>
        <p>{{ $t('tournaments.mulligan') }}</p>
        <div class="yellow-strip my-4 mx-auto" />
        <p>
          {{
            $replacePlaceholder(
              $t('tournaments.mulliganTooltip'),
              '{mulligan}',
              $t('tournaments.mulligan'),
            )
          }}
        </p>
      </div>
    </template>
    <div
      v-for="(resource, i) in resources"
      :key="i"
      class="flexing"
      :class="{ 'revert-skew': $isWsm }"
    >
      {{ $filters.$formatNumber(resource.value) || 0 }}
      <app-main-icon
        :icon-size="38"
        :icon-name="resource.icon"
        :hide-tooltip="resource.icon === MULLIGANS"
      />
    </div>
  </tippy>
</template>

<script lang="ts">
import { MULLIGANS, TOURNAMENT_POINTS } from '@/globalVariables'
import { defineComponent, type PropType } from 'vue'

interface Resource {
  value: number
  icon: string
}

interface ComponentData {
  TOURNAMENT_POINTS: typeof TOURNAMENT_POINTS
  MULLIGANS: typeof MULLIGANS
}

export default defineComponent({
  props: {
    resources: {
      type: Array as PropType<Resource[]>,
      required: true,
    },
  },
  data(): ComponentData {
    return {
      TOURNAMENT_POINTS,
      MULLIGANS,
    }
  },
})
</script>

<style lang="scss" scoped>
.tournament-box {
  width: 13.75rem;
  height: 4.125rem;
  border-style: solid;
  border-width: 0.125rem;
  border-image-slice: 1;
  @if $isSsm {
    background-color: #363e5d;
    border-image-source: linear-gradient(to top, #fff284, #f6c717);
    box-shadow:
      inset -0.188rem 0 2.5rem 0 rgba(6, 34, 55, 0.48),
      inset -0.0188rem -0.188rem 2.5rem 0 rgba(6, 34, 55, 0.48);
  }
  @if $isWsm {
    background-color: #0f3251;
    border-image-source: linear-gradient(to top, #fff284, #f6c717);
    box-shadow:
      inset -0.188rem 0 2.5rem 0 rgba(51, 123, 214, 0.19),
      inset -0.0188rem -0.188rem 2.5rem 0 rgba(6, 34, 55, 0.48);
  }

  &.is-wide {
    width: 16.25rem;
  }
}

.yellow-strip {
  height: 0.125rem;
}
</style>
