<template>
  <main
    v-if="loaded && $route.query?.pass"
    class="passes-main flexing w-full h-full"
    :class="[
      `is-${$route.query?.pass}-pass`,
      { 'is-premium': $route.meta.isPremiumRoute, 'safe-area': !$route.meta.isPremiumRoute },
    ]"
    :data-event-theme="namespace"
  >
    <passes-header v-if="!$route.meta.isPremiumRoute" />
    <passes-sub-header />
    <template v-if="$route.query?.pass === PassType.Event && eventPass">
      <template v-if="!eventCollectionActive">
        <event-pass1-main v-if="getPassMaxTier === 1" />
        <event-pass3-main v-else-if="getPassMaxTier === 3" />
      </template>
      <section v-else class="passes-main-event-ended-message flexing flex-col flex-grow w-full">
        <event-message :event-ended="true" />
      </section>
    </template>
    <champion-pass-main v-else-if="$route.query?.pass === PassType.Champion && championPass" />
    <master-pass-main v-else-if="$route.query?.pass === PassType.Master && masterPass" />
  </main>
</template>

<script lang="ts">
import { PassType } from '@/interfaces/events/Passes'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventMessage from '../EventMessage.vue'
import ChampionPassMain from './ChampionPass/ChampionPassMain.vue'
import EventPass1Main from './EventPass1/EventPass1Main.vue'
import EventPass3Main from './EventPass3/EventPass3Main.vue'
import MasterPassMain from './MasterPass/MasterPassMain.vue'
import PassesHeader from './PassesHeader.vue'
import PassesSubHeader from './PassesSubHeader.vue'

interface ComponentData {
  PassType: typeof PassType
  loaded: boolean
}

export default defineComponent({
  name: 'PassesMain',
  components: {
    PassesHeader,
    PassesSubHeader,
    EventPass1Main,
    EventPass3Main,
    ChampionPassMain,
    MasterPassMain,
    EventMessage,
  },
  data(): ComponentData {
    return {
      PassType,
      loaded: false,
    }
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
      championPass: 'getChampionPass',
      masterPass: 'getMasterPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventCustomProps: 'getEventCustomProps',
      eventCollectionActive: 'getEventCollectionActive',
    }),
    getPassMaxTier(): number {
      return this.eventPass?.length
    },
  },
  watch: {
    $route(): void {
      this.onRouteChange()
    },
    async '$route.query.reloadData'(): Promise<void> {
      if (!this.$route.query.reloadData) return

      await this.loadEventOffers(true)

      this.$router.replace({
        query: {
          ...this.$route.query,
          reloadData: undefined,
        },
      })
    },
  },
  async created(): Promise<void> {
    await this.loadEventOffers()
    this.loaded = true
    this.onRouteChange()
  },
  methods: {
    ...mapActions(usePremiumStore, ['loadEventOffers']),
    onRouteChange(): void {
      // Toto sa z nejakeho dovodu spusta aj pri odchode z routy.
      if (
        !['EventsPasses', 'PremiumEventPasses']
          .map(this.$getWebView)
          .includes(this.$route.name.toString())
      )
        return

      // Toto je duplicita z route guardu, ale potrebujeme ju tu mat, kedze ten sa nespusta pri zmene query params.
      if (
        !this.$route.query?.pass ||
        !Object.values(this.eventCustomProps.passes).includes(this.$route.query?.pass as PassType)
      ) {
        this.$router.replace({
          query: {
            ...this.$route.query,
            pass: this.eventCustomProps.routes[
              this.$route.meta.isPremiumRoute ? 'premium' : 'passes'
            ][this.eventCollectionActive ? 'collection.query.pass' : 'progress.query.pass'],
          },
        })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --events-passes-event-bg: url('#{$path-events}challenge-cup/passes/event-pass-bg.avif');
  --events-passes-premium-event-bg: url('#{$path-events}challenge-cup/passes/premium-event-pass-bg.avif');
  --events-passes-champion-bg: url('#{$path-events}challenge-cup/passes/champion-pass-bg.avif');
  --events-passes-premium-champion-bg: url('#{$path-events}challenge-cup/passes/premium-champion-pass-bg.avif');
}

[data-event-theme='frozen'] {
  --events-passes-event-bg: url('#{$path-events}frozen-championship/passes/event-pass-bg-v1.avif');
  --events-passes-premium-event-bg: url('#{$path-events}frozen-championship/passes/premium-event-pass-bg.avif');
  --events-passes-champion-bg: url('#{$path-events}frozen-championship/passes/champion-pass-bg-v1.avif');
  --events-passes-premium-champion-bg: url('#{$path-events}frozen-championship/passes/premium-champion-pass-bg.avif');
}

[data-event-theme='discipline'] {
  --events-passes-master-bg: url('#{$path-events}discipline-event/passes/master-pass-bg.avif');
  --events-passes-premium-master-bg: url('#{$path-events}discipline-event/passes/premium-master-pass-bg.avif');
}

.passes-main {
  flex-direction: column;

  &.is-event-pass {
    &.is-premium {
      @include background(var(--events-passes-premium-event-bg), cover, bottom);
    }
  }

  &.is-champion-pass {
    @include background(var(--events-passes-champion-bg), cover, bottom);

    &.is-premium {
      background-image: var(--events-passes-premium-champion-bg);
    }
  }

  &.is-master-pass {
    @include background(var(--events-passes-master-bg), cover, bottom);

    &.is-premium {
      background-image: var(--events-passes-premium-master-bg);
    }
  }
}
</style>
