<template>
  <main
    v-if="namespace && loaded"
    class="events-view absolute w-full h-full flex overflow-hidden"
    :data-event-theme="namespace"
    :style="setBackgroundImage"
  >
    <events-header :views="supportedViews" />
    <component :is="activeView" />
  </main>
</template>

<script lang="ts">
import EventsArena from '@/components/Events/ArenaComponents/ArenaMain.vue'
import EventsOverview from '@/components/Events/EventsOverview/EventsOverview.vue'
import EventsRankings from '@/components/Events/EventsRankings/EventsRankings.vue'
import EventsWarehouse from '@/components/Events/EventsWarehouse/EventsWarehouse.vue'
import EventsHeader from '@/components/Events/GlobalEventsComponents/EventsHeader.vue'
import LuckyWheelMain from '@/components/Events/LuckyWheelComponents/LuckyWheelMain.vue'
import MilestonesMain from '@/components/Events/MilestonesComponents/MilestonesMain.vue'
import OffersMain from '@/components/Events/OffersComponent/OffersMain.vue'
import PassesMain from '@/components/Events/PassesComponents/PassesMain.vue'
import Playoff from '@/components/Events/PlayoffComponents/Playoff.vue'
import TaskchainMain from '@/components/Events/TaskchainComponents/TaskchainMain.vue'
import EventsTraining from '@/components/Events/TrainingComponents/TrainingMain.vue'
import { pathImages } from '@/globalVariables'
import { PlayoffType } from '@/interfaces/events/Playoff'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useEventPlayoffStore } from '@/store/pinia/events/playoffStore'
import type { EventView, EventViews } from '@/interfaces/events/EventViews'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  loaded: boolean
}

export default defineComponent({
  name: 'EventsMainView',
  components: {
    EventsHeader,
    TaskchainMain,
    LuckyWheelMain,
    PassesMain,
    MilestonesMain,
    Playoff,
    OffersMain,
    EventsWarehouse,
    EventsOverview,
    EventsTraining,
    EventsArena,
    EventsRankings,
  },
  data(): ComponentData {
    return {
      pathImages,
      loaded: false,
    }
  },
  computed: {
    ...mapState(useEventPlayoffStore, {
      state: 'getPlayoffState',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    supportedViews(): EventViews {
      return {
        ...(this.namespace === 'discipline' && {
          EventsOverview: 'EventsOverview',
          EventsOverviewWeb: 'EventsOverview',
        }),
        ...(this.namespace === 'discipline' && {
          EventsArena: 'EventsArena',
          EventsArenaWeb: 'EventsArena',
        }),
        ...(this.namespace === 'discipline' && {
          EventsTraining: 'EventsTraining',
          EventsTrainingWeb: 'EventsTraining',
        }),
        ...((this.namespace === 'challenge_cup' || this.namespace === 'frozen') && {
          EventsTaskchain: 'TaskchainMain',
          EventsTaskchainWeb: 'TaskchainMain',
        }),
        ...(this.namespace === 'frozen' && {
          EventsWarehouse: 'EventsWarehouse',
          EventsWarehouseWeb: 'EventsWarehouse',
        }),
        ...((this.namespace === 'challenge_cup' || this.namespace === 'frozen') && {
          EventsMilestones: 'MilestonesMain',
          EventsMilestonesWeb: 'MilestonesMain',
        }),
        ...((this.namespace === 'challenge_cup' || this.namespace === 'frozen') && {
          EventsPlayOff: 'Playoff',
          EventsPlayOffWeb: 'Playoff',
        }),
        ...(this.namespace === 'challenge_cup' && {
          EventsLuckyWheelWeb: 'LuckyWheelMain',
          EventsLuckyWheel: 'LuckyWheelMain',
        }),
        EventsPasses: 'PassesMain',
        EventsPassesWeb: 'PassesMain',
        ...((this.namespace === 'challenge_cup' || this.namespace === 'frozen') && {
          EventsOffers: 'OffersMain',
          EventsOffersWeb: 'OffersMain',
        }),
        ...(this.namespace === 'discipline' && {
          EventsRankings: 'EventsRankings',
          EventsRankingsWeb: 'EventsRankings',
        }),
        ...(this.namespace === 'discipline' && {
          EventsMilestones: 'MilestonesMain',
          EventsMilestonesWeb: 'MilestonesMain',
        }),
      }
    },
    activeView(): EventView {
      return this.supportedViews[this.$route?.name.toString() ?? 'TeaserMain'] || 'TeaserMain'
    },
    setBackgroundImage(): string {
      let url
      switch (this.namespace) {
        case 'challenge_cup': {
          // FIXME: Toto sa asi zabudlo presunut do CC adresara.
          if (this.$isWsm) {
            url = `${this.pathImages}events/${this.activeView}-bg.avif`
          }
          if (this.$isSsm) {
            url = `${this.pathImages}events/challenge-cup/${this.activeView}-bg.avif`
          }
          break
        }
        case 'frozen': {
          url = `${this.pathImages}events/frozen-championship/${this.activeView}-bg-v1.avif`
          // FIXME: Toto setnut radsej priamov playoff, ako to podmienkovat tu a prerobit na CSS.
          if (
            this.namespace === 'frozen' &&
            this.$route.query?.tournamentId &&
            this.state &&
            this.state[0]?.playoff_type === PlayoffType.Masters
          ) {
            url =
              'https://appspowerplaymanager.vshcdn.net/images/winter-sports/events/playoff/masters-playoff-bg.avif'
          }
          break
        }
        case 'discipline': {
          url = `${this.pathImages}events/discipline-event/${this.activeView}-bg.avif`
          break
        }
      }

      if (!url) return

      return `background-image: url(${url})`
    },
  },
  async created(): Promise<void> {
    await this.eventWindowUpdater()
    this.loaded = true
  },
  methods: {
    ...mapActions(useEventInfoStore, ['loadEvent']),
    async eventWindowUpdater(): Promise<void> {
      if (!this.namespace) await this.loadEvent()

      // TODO: window.activeEvent treba vyhodit, mozme si to tahat zo storka.
      window.activeEvent = this.namespace ?? 'default'
    },
  },
})
</script>

<style lang="scss" scoped>
.events-view {
  padding-top: 5.9rem;
  flex-direction: column;
  @include background(null, cover);
}
</style>
