<template>
  <main
    class="teaser-main flex flex-col justify-between w-full h-full relative safe-area"
    :class="{
      'is-discipline-event': namespace === 'discipline',
      'is-teaser-active': eventTeaserActive,
    }"
    :data-event-theme="namespace"
  >
    <teaser-header />
    <teaser-content />
    <section
      class="teaser-img absolute bottom-0"
      :class="{ [`teaser-img-discipline-${eventDisciplineId}`]: !!eventDisciplineId }"
    />
    <teaser-footer v-if="!eventTeaserActive" />
  </main>
</template>

<script lang="ts">
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useRenderQueueStore } from '@/store/pinia/renderQueueStore'
import { RenderQueueComponent } from '@/enums/RenderQueueTypes'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import TeaserContent from './TeaserContent.vue'
import TeaserFooter from './TeaserFooter.vue'
import TeaserHeader from './TeaserHeader.vue'

export default defineComponent({
  name: 'TeaserMain',
  components: {
    TeaserHeader,
    TeaserFooter,
    TeaserContent,
  },
  computed: {
    ...mapState(useRenderQueueStore, ['getCurrentComponentInQueue']),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventDisciplineId: 'getEventDisciplineId',
      eventTeaserActive: 'getEventTeaserActive',
    }),
  },
  beforeUnmount(): void {
    if (
      this.eventTeaserActive &&
      this.getCurrentComponentInQueue === RenderQueueComponent.EventsTeaser
    ) {
      this.setNextInRenderQueue()
    }
  },
  methods: {
    ...mapActions(useRenderQueueStore, ['setNextInRenderQueue']),
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variables.scss';

[data-event-theme='challenge_cup'] {
  --events-teaser-bg: url('#{$path-events}challenge-cup/teaser/teaser-bg.avif');
  --events-teaser-img: url('#{$path-events}challenge-cup/teaser/teaser-img.webp');
}

[data-event-theme='frozen'] {
  --events-teaser-bg: url('#{$path-events}frozen-championship/teaser/teaser-bg.avif');
  --events-teaser-img: url('#{$path-events}frozen-championship/teaser/teaser-img.webp');
}

[data-event-theme='discipline'] {
  --events-teaser-bg: url('#{$path-events}discipline-event/teaser/teaser-bg.avif');

  @for $i from 1 through 20 {
    --events-teaser-img-#{$i}: url('#{$path-events}discipline-event/teaser/teaser-img-#{$i}.webp');
  }
}

.teaser-main {
  @include background(var(--events-teaser-bg), cover);

  &.is-discipline-event {
    &::before {
      content: '';
      position: absolute;
      bottom: 10rem;
      right: 0;
      width: 100%;
      height: 7.5rem;
      background: linear-gradient(0deg, rgba(2, 20, 34, 0.7) 40%, rgba(35, 52, 65, 0) 100%);
    }

    &.is-teaser-active::before {
      bottom: 2.5rem;
    }
  }

  .teaser-img {
    width: 48.9375rem;
    height: 58.6875rem;
    @include background(var(--events-teaser-img), cover);

    @for $i from 1 through 20 {
      &.teaser-img-discipline-#{$i} {
        width: 63.938rem;
        height: 67.5rem;
        @include background(var(--events-teaser-img-#{$i}), contain);

        @if $isSsm {
          width: 78.438rem;
          background-position: calc(63.938rem - 78.438rem) center;
        }
      }
    }
  }
}
</style>
