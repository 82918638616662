import {
  milestonesConfig as MILESTONES_CONFIG_ENDPOINT,
  milestonesState as MILESTONES_STATE_ENDPOINT,
  personalMilestoneClaimRewards as MILESTONES_CLAIM_REWARDS_ENDPOINT,
  milestonesTotalPointsEndpoint as MILESTONES_RUNTIME_DATA_ENDPOINT,
  clubContibutorMilestone as MILESTONES_CLUB_CONTRIBUTORS_ENDPOINT,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'
import { defineStore } from 'pinia'
import { MilestoneType } from '@/interfaces/events/Milestones'
import type MilestonesConfigApiResponse from '@/interfaces/responses/events/milestones/MilestonesConfigApiResponse'
import type MilestonesStateApiResponse from '@/interfaces/responses/events/milestones/MilestonesStateApiResponse'
import type { MilestonesClaimApiResponse } from '@/interfaces/responses/events/milestones/MilestonesClaimApiResponse'
import type { MilestonesRuntimeDataApiResponse } from '@/interfaces/responses/events/milestones/MilestonesRuntimeDataApiResponse'
import type { MilestonesClubContributorsApiResponse } from '@/interfaces/responses/events/milestones/MilestonesClubContributorsApiResponse'

export interface MilestonesState {
  config: MilestonesConfigApiResponse | null
  state: MilestonesStateApiResponse | null
  clubContributors: MilestonesClubContributorsApiResponse | null
  runtime: Partial<MilestonesRuntimeDataApiResponse> | null
}

export const useMilestonesStore = defineStore('milestonesStore', {
  state: (): MilestonesState => ({
    config: null,
    state: null,
    clubContributors: null,
    runtime: null,
  }),
  getters: {
    getProgressingEndDate(): MilestonesConfigApiResponse['date_end_time'] | null {
      return this.config?.date_end_time ?? null
    },
    getRewardingEndDate(): MilestonesConfigApiResponse['date_rewarding_end_time'] | null {
      return this.config?.date_rewarding_end_time ?? null
    },
    getProgressingEndsIn(): MilestonesConfigApiResponse['date_end'] | null {
      return this.config?.date_end || null
    },
    getRewardingEndsIn(): MilestonesConfigApiResponse['date_rewarding_end'] | null {
      return this.config?.date_rewarding_end || null
    },
    getBranches(): MilestonesConfigApiResponse['branches'] | null {
      return this.config?.branches ?? null
    },
    getIndividualBranchTasks(): MilestonesConfigApiResponse['branches'][0]['milestones'] | null {
      return (
        this.getBranches?.find(
          ({ type }: MilestonesConfigApiResponse['branches'][0]): boolean =>
            type === MilestoneType.Individual,
        ).milestones ?? null
      )
    },
    getPremiumBranchTasks(): MilestonesConfigApiResponse['branches'][0]['milestones'] | null {
      return (
        this.getBranches.find(
          ({ type }: MilestonesConfigApiResponse['branches'][0]): boolean =>
            type === MilestoneType.Premium,
        ).milestones ?? null
      )
    },
    getClubBranchTasks(): MilestonesConfigApiResponse['branches'][0]['milestones'] | null {
      return (
        this.getBranches.find(
          ({ type }: MilestonesConfigApiResponse['branches'][0]): boolean =>
            type === MilestoneType.Club,
        ).milestones ?? null
      )
    },
    getRepeatable(): Exclude<MilestonesConfigApiResponse['repeatable'], false> | null {
      // Tu musi byt || kedze ?? by nam nechalo false.
      return this.config?.repeatable || null
    },
    getRewards(): MilestonesStateApiResponse['rewarded'] | null {
      return this.state?.rewarded ?? null
    },
    getIndividualBranchRewards(): MilestonesStateApiResponse['rewarded'][0]['milestones'] | null {
      return (
        this.getRewards?.find(
          ({ type }: MilestonesStateApiResponse['rewarded'][0]): boolean =>
            type === MilestoneType.Individual,
        ).milestones ?? null
      )
    },
    getPremiumBranchRewards(): MilestonesStateApiResponse['rewarded'][0]['milestones'] | null {
      return (
        this.getRewards?.find(
          ({ type }: MilestonesStateApiResponse['rewarded'][0]): boolean =>
            type === MilestoneType.Premium,
        ).milestones ?? null
      )
    },
    /*
    // Toto neexistuje.
    getClubBranchRewards(): MilestonesStateApiResponse['rewarded'][0]['milestones'] | null {
      return (
        this.getRewards?.find(
          ({ type }: MilestonesStateApiResponse['rewarded'][0]): boolean =>
            type === MilestoneType.Club
        ).milestones ?? null
      )
    },
    */
    getCurrentMilestonePoints(): MilestonesStateApiResponse['milestone'] | null {
      return this.state?.milestone ?? null
    },
    getCurrentMilestoneNumber(): MilestonesStateApiResponse['milestone']['number'] | null {
      return this.state?.milestone?.number ?? null
    },
    isClubMilestoneClaimed(): MilestonesStateApiResponse['claimed' | null] {
      if (this.state.type !== MilestoneType.Club) return null
      return this.state?.claimed ?? null
    },
    isPassActive(): MilestonesStateApiResponse['pass_active'] | null {
      return this.state?.pass_active ?? null
    },
    getClubContributors(): MilestonesClubContributorsApiResponse['contributions'] {
      return this.clubContributors?.contributions || null
    },
    // Runtime data:
    getMilestonesTotalPoints(): MilestonesRuntimeDataApiResponse['total_points'] {
      return this.runtime?.total_points ?? 0
    },
    getMilestonesClaimableCount(): MilestonesRuntimeDataApiResponse['claimable'] {
      return this.runtime?.claimable ?? 0
    },
  },
  actions: {
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config && !force) return

      try {
        const data = await internalAxios.get<{}, MilestonesConfigApiResponse>(
          MILESTONES_CONFIG_ENDPOINT,
        )
        if (!data) return
        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadState({ type = MilestoneType.Individual }: { type: MilestoneType }): Promise<void> {
      try {
        const data = await internalAxios.post<{}, MilestonesStateApiResponse>(
          MILESTONES_STATE_ENDPOINT,
          { type },
        )
        if (!data) return
        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async claimRewards({
      milestone,
      premium = false,
      type = MilestoneType.Individual,
    }: {
      milestone: number
      premium?: boolean
      type: MilestoneType
    }): Promise<void> {
      try {
        await internalAxios.post<{}, MilestonesClaimApiResponse>(
          MILESTONES_CLAIM_REWARDS_ENDPOINT,
          {
            type,
            milestone,
            premium,
          },
        )
        await this.loadState({ type })
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadClubContributors(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, MilestonesClubContributorsApiResponse>(
          MILESTONES_CLUB_CONTRIBUTORS_ENDPOINT,
        )
        if (!data) return
        this.clubContributors = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadRuntimeData(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, MilestonesRuntimeDataApiResponse>(
          MILESTONES_RUNTIME_DATA_ENDPOINT,
          {
            type: MilestoneType.Individual,
          },
        )
        if (!data) return
        this.runtime = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    setMilestonesTotalPoints(data: MilestonesRuntimeDataApiResponse['total_points']): void {
      if (isNaN(data)) return
      this.runtime = this.runtime || {}
      this.runtime.total_points = data
    },
    setMilestonesClaimableCount(data: MilestonesRuntimeDataApiResponse['claimable']): void {
      if (isNaN(data)) return
      this.runtime = this.runtime || {}
      this.runtime.claimable = data
    },
  },
})
