export enum EventType {
  ChallengeCup = 'challenge_cup',
  FrozenChampionship = 'frozen',
  // WinterSeries = 'discipline',
  DisciplineEvent = 'discipline',
}

export enum EventMechanics {
  Taskchain = 'taskchain',
  Milestones = 'milestones',
  Playoff = 'playoff',
  MastersPlayoff = 'masters_playoff',
  LuckyWheel = 'lucky_wheel',
}
