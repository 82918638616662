<template>
  <main
    v-if="eventPass?.length"
    class="event-pass-detail fixed top-0 left-0 flexing flex-col w-full h-full z-10"
    :class="[`tier-${$route.query?.tier}`]"
    :data-event-theme="namespace"
  >
    <event-pass3-detail-header />
    <event-pass3-detail-sub-header />
    <event-pass3-detail-body />
  </main>
</template>

<script lang="ts">
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import EventPass3DetailBody from './EventPass3DetailBody.vue'
import EventPass3DetailHeader from './EventPass3DetailHeader.vue'
import EventPass3DetailSubHeader from './EventPass3DetailSubHeader.vue'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'

export default defineComponent({
  name: 'EventPass3DetailMain',
  components: {
    EventPass3DetailHeader,
    EventPass3DetailSubHeader,
    EventPass3DetailBody,
  },
  computed: {
    ...mapState(usePremiumStore, {
      eventPass: 'getEventPass',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
  },
})
</script>

<style lang="scss" scoped>
$tiers: (1, 2, 3);

[data-event-theme='frozen'] {
  @each $tier in $tiers {
    --events-passes-event-tier-#{$tier}-bg: url('#{$path-events}frozen-championship/passes/event-pass-tier-#{$tier}-bg.avif');
  }
}

.event-pass-detail {
  flex-direction: column;
  @include background(null, cover);

  @each $tier in $tiers {
    &.tier-#{$tier} {
      background-image: var(--events-passes-event-tier-#{$tier}-bg);
    }
  }
}
</style>
