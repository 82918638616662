<template>
  <teleport to="#app_id">
    <div
      v-if="
        !(
          [
            EVENT_MILESTONES,
            GAME_PASS_MILESTONES,
            PIGGY_BANK_MILESTONES,
            TOURNAMENTS_MILESTONES,
            TOURNAMENT_START,
            TOURNAMENT_END,
          ] as string[]
        ).includes(sessionData.type)
      "
      :id="'session-message-' + typesOfData[sessionData.type]?.divId"
      :data-cy="'session-message-' + typesOfData[sessionData.type]?.divId"
      :class="'session-message theme-' + typesOfData[sessionData.type]?.theme"
    >
      <div
        :class="
          sessionData.type === EVENT_PROGRESS_DATA
            ? 'session-message'
            : 'session-message-theme-motive'
        "
      />
      <div v-if="typesOfData[sessionData.type]?.image" class="session-message-img">
        <img :src="pathImages + typesOfData[sessionData.type].image" />
      </div>
      <div
        class="session-message-content flex flex-col justify-between"
        :class="{
          'session-message-content--event':
            sessionData.type === EVENT_PROGRESS_DATA || sessionData.type === EVENT_MILESTONES,
        }"
      >
        <h2
          v-if="typesOfData[sessionData.type]?.title || typesOfData[sessionData.type]?.rawTitle"
          class="smsg-h2 text-texts-standard-important text-40 font-bold uppercase"
        >
          {{
            typesOfData[sessionData.type]?.rawTitle
              ? typesOfData[sessionData.type].rawTitle
              : $t(typesOfData[sessionData.type].title)
          }}
        </h2>
        <p
          v-if="typesOfData[sessionData.type]?.subtitle"
          class="smsg-p1 text-texts-standard-additional text-34"
        >
          {{
            typesOfData[sessionData.type].discipline
              ? replaceSubtitle(
                  $replacePlaceholder(
                    $t(typesOfData[sessionData.type].subtitle),
                    '{discipline}',
                    $translateDiscipline(typesOfData[sessionData.type].discipline),
                  ),
                  typesOfData[sessionData.type].target,
                  typesOfData[sessionData.type].threshold,
                )
              : replaceSubtitle(
                  $t(typesOfData[sessionData.type].subtitle),
                  typesOfData[sessionData.type].target,
                  typesOfData[sessionData.type].threshold,
                )
          }}
        </p>
        <p
          v-else-if="typesOfData[sessionData.type]?.discipline"
          class="smsg-p1 text-texts-standard-additional text-34"
        >
          {{ $translateDiscipline(typesOfData[sessionData.type].discipline) }}
        </p>
        <div
          v-if="sessionData.type === EVENT_PROGRESS_DATA"
          class="smsg-p2 justify-start smsg-background"
        >
          <p
            v-if="sessionData.progress.value === sessionData.progress.target"
            class="smsg-p2 text-texts-standard-default text-34 uppercase"
          >
            {{ $t(typesOfData[sessionData.type].text) }}
          </p>
          <app-progress-bar
            v-else
            :bar-width="30.875"
            :bar-height="0.8125"
            :text-size="2.25"
            :actual="sessionData.progress.value"
            :goal="sessionData.progress.target"
            :counts="true"
            :show-bottom-status="false"
            class="task-progress task-progress--margined"
          />
        </div>
        <p v-else class="smsg-p2 text-texts-standard-default text-34 uppercase session-title">
          {{ $t(typesOfData[sessionData.type].text) }}
        </p>
      </div>
    </div>
    <div
      v-else-if="([TOURNAMENT_START, TOURNAMENT_END] as string[]).includes(sessionData.type)"
      class="tournament-session-message"
      :class="`is-${sessionData.type.replace('_', '-')}`"
    >
      <div class="tournament-session-message-icon" />
      <div class="flex flex-col justify-center items-center space-y-6">
        <div
          class="tournament-session-message-title flexing text-60 text-texts-standard-default font-bold text-left uppercase space-x-6"
          :class="`is-${sessionData.type.replace('_', '-')}`"
        >
          <app-discipline-icon
            class="discipline-icon flex-shrink-0"
            :discipline-id="typesOfData[sessionData.type].discipline"
            :size="90"
            theme="light"
          />
          <p class="w-full truncate">
            {{ typesOfData[sessionData.type].rawTitle }}
          </p>
        </div>
        <div
          class="tournament-session-message-text flexing text-48 text-texts-standard-important font-medium"
        >
          <p class="w-full">{{ $t(typesOfData[sessionData.type].text) }}</p>
        </div>
      </div>
    </div>
    <div
      v-else-if="sessionData.type === EVENT_MILESTONES"
      :data-event-theme="namespace"
      class="club-message-milestone"
    >
      <div v-if="sessionData.type !== EVENT_MILESTONES" class="session-message-theme-motive" />
      <div class="club-milestone">
        <div class="cup" />
        <div class="texts">
          <div class="top-box">
            <p class="gradient-text-light font-bold text-40">
              {{
                sessionData.taskType === INDIVIDUAL_MILESTONE_REACHED
                  ? $t('event.individualMilestoneReached')
                  : $t('event.clubMilestoneReached')
              }}
            </p>
          </div>
          <div
            class="rewards-section-milestone flexing text-60"
            :class="{
              'is-repeatable': sessionData.is_repeatable,
            }"
          >
            <p v-if="!sessionData.is_repeatable" class="gradient-text-light font-bold">
              {{ sessionData.milestone }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="sessionData.type === GAME_PASS_MILESTONES" class="game-pass-milestone">
      <p
        class="text-texts-standard-default text-36 uppercase session-title font-bold flexing w-full"
      >
        {{ $t(typesOfData[sessionData.type].text) }}
        <span class="text-60 font-bold gradient-text-light">{{ sessionData.new_milestone }}</span>
      </p>
    </div>

    <div v-else-if="sessionData.type === TOURNAMENTS_MILESTONES" class="tournaments-milestone">
      <p
        class="text-texts-standard-default text-36 uppercase session-title font-bold flexing w-full"
      >
        {{ $t(typesOfData[sessionData.type].text) }}
        <span
          class="text-60 font-bold"
          :class="{
            'gradient-text-light': !sessionData.repeatable,
            'is-repeatable': sessionData.repeatable,
          }"
        >
          {{ !sessionData.repeatable ? sessionData.new_milestone : '' }}
        </span>
      </p>
    </div>

    <div v-else-if="sessionData.type === PIGGY_BANK_MILESTONES" class="piggy-bank-milestone">
      <div class="session-message-theme-motive">
        <img
          class="piggy-bank-milestone__img"
          :src="`${pathImages}premium/piggy-bank/session-msg-piggy-${sessionData.piggy_bank_reward.toLowerCase()}-${sessionData.new_piggy_bank_rarity}.avif`"
        />
      </div>
      <div class="piggy-bank-milestone__content flexing flex-col text-texts-standard-default">
        <div class="piggy-bank-icon" />
        <div class="piggy-bank-milestone-title text-32">
          {{ $t(`piggyBank.piggyBank${capitalize(sessionData.new_piggy_bank_rarity)}`) }}
          <span class="text-texts-standard-important uppercase font-bold">
            {{ $t('common.unlocked') }}
          </span>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import {
  EQUIPMENT,
  EQUIPMENT_DAMAGED,
  EQUIPMENT_DELIVERED,
  EQUIPMENT_REPAIRED,
  EQUIPMENT_UPGRADED,
  EVENT_MILESTONES,
  EVENT_PROGRESS_DATA,
  INDIVIDUAL_MILESTONE_REACHED,
  QUEST_COMPLETED,
  GAME_PASS_MILESTONES,
  PIGGY_BANK_MILESTONES,
  TOURNAMENTS_MILESTONES,
  TOURNAMENT_START,
  TOURNAMENT_END,
  pathImages,
} from '@/globalVariables'
import { capitalize } from '@/helpers'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import type { SessionData } from '@/store/pinia/responseTaskStore'
import type { Nullable } from '@/interfaces/utils'

const EQUIPMENT_TYPE_MAPPING = {
  [EQUIPMENT_REPAIRED]: 'equipmentShop.repairComplete',
  [EQUIPMENT_UPGRADED]: 'equipmentShop.upgradeComplete',
  [EQUIPMENT_DAMAGED]: 'equipmentShop.equipmentBroke',
  [EQUIPMENT_DELIVERED]: 'equipmentShop.deliverComplete',
}

const QUEST_TYPE_MAPPING = {
  dailyTask: 'career.dailyQuests',
  newCareerQuest: 'career.career',
}

const QUEST_IMAGE_MAPPING = {
  dailyTask: 'dailyTask/sessionMessages/calendar.avif',
  newCareerQuest: 'career/sessionMessages/career_building.avif',
}

interface ComponentData {
  EVENT_PROGRESS_DATA: typeof EVENT_PROGRESS_DATA
  EVENT_MILESTONES: typeof EVENT_MILESTONES
  GAME_PASS_MILESTONES: typeof GAME_PASS_MILESTONES
  PIGGY_BANK_MILESTONES: typeof PIGGY_BANK_MILESTONES
  TOURNAMENTS_MILESTONES: typeof TOURNAMENTS_MILESTONES
  INDIVIDUAL_MILESTONE_REACHED: typeof INDIVIDUAL_MILESTONE_REACHED
  TOURNAMENT_START: typeof TOURNAMENT_START
  TOURNAMENT_END: typeof TOURNAMENT_END
  pathImages: typeof pathImages
}

interface DataItem {
  title?: string
  text: string
  discipline: Nullable<string>
  subtitle?: string
  target?: string | number
  threshold?: string
  image?: string
  theme: number
  divId: string
  rawTitle?: string
}

interface DataMap {
  [key: string]: DataItem
}

export default defineComponent({
  name: 'SessionMessage',
  props: {
    sessionData: {
      type: Object as PropType<SessionData>,
      required: true,
    },
    timeToHide: {
      type: Number,
      default: 3000,
    },
  },
  data(): ComponentData {
    return {
      EVENT_PROGRESS_DATA,
      EVENT_MILESTONES,
      INDIVIDUAL_MILESTONE_REACHED,
      GAME_PASS_MILESTONES,
      PIGGY_BANK_MILESTONES,
      TOURNAMENTS_MILESTONES,
      TOURNAMENT_START,
      TOURNAMENT_END,
      pathImages,
    }
  },
  computed: {
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
      eventName: 'getEventName',
      eventAssetsDirectory: 'getEventAssetsDirectory',
    }),
    typesOfData(): DataMap {
      return {
        [EQUIPMENT]: {
          title: this.sessionData.equipmentName,
          text: EQUIPMENT_TYPE_MAPPING[this.sessionData.operationType] ?? '',
          discipline: this.sessionData.disciplineId,
          image: `equipment/items/session/${this.sessionData.disciplineId}/${this.sessionData.categoryId}-${this.sessionData.levelId}.avif`,
          theme: this.sessionData.operationType === EQUIPMENT_DAMAGED ? 2 : 1,
          divId: this.sessionData.operationType,
        },
        [QUEST_COMPLETED]: {
          title: QUEST_TYPE_MAPPING[this.sessionData.taskType],
          text: 'career.questDone',
          discipline: this.sessionData?.disciplineId,
          subtitle: this.sessionData.task,
          target: this.sessionData.target || '',
          threshold: this.sessionData.threshold || '',
          image: QUEST_IMAGE_MAPPING[this.sessionData.taskType],
          theme: 1,
          divId: this.sessionData.taskType,
        },
        [EVENT_PROGRESS_DATA]: {
          rawTitle: this.eventName || 'Event',
          text: 'career.questDone',
          discipline: this.sessionData?.disciplineId,
          subtitle: this.sessionData.task,
          target: this.sessionData?.progress?.target || '',
          threshold: this.sessionData.threshold || '',
          image: `events/${this.eventAssetsDirectory}/taskchain/tasks/session-message-team-avatar-${this.sessionData.team}.avif`,
          theme: 1,
          divId: this.sessionData.taskType,
        },
        [EVENT_MILESTONES]: {
          rawTitle: this.eventName || 'Event',
          text: 'career.questDone',
          discipline: null,
          theme: 1,
          divId: this.sessionData.taskType,
        },
        [GAME_PASS_MILESTONES]: {
          text: 'event.individualMilestoneReached',
          discipline: null,
          theme: 1,
          divId: this.sessionData.taskType,
        },
        [TOURNAMENTS_MILESTONES]: {
          text: 'event.individualMilestoneReached',
          discipline: null,
          theme: 1,
          divId: this.sessionData.taskType,
        },
        [TOURNAMENT_START]: {
          rawTitle: this.sessionData?.tournamentName,
          text: 'tournaments.tournamentStarted',
          discipline: this.sessionData?.disciplineId,
          theme: 1,
          divId: 'tournament-start',
        },
        [TOURNAMENT_END]: {
          rawTitle: this.sessionData?.tournamentName,
          text: 'tournaments.tournamentEnded',
          discipline: this.sessionData?.disciplineId,
          theme: 1,
          divId: 'tournament-end',
        },
      }
    },
  },
  created(): void {
    this.setUpTimer()
  },
  methods: {
    capitalize,
    ...mapActions(useResponseTaskStore, ['hideSessionPopUp']),
    setUpTimer(): void {
      setTimeout((): void => {
        this.hideSessionPopUp()
      }, this.timeToHide)
    },
    replaceSubtitle(text: string, target: string | number, threshold: string = '0'): string {
      let output = this.$replaceUntranslatablePlaceholder(text, '{grandprize}')
      output = this.$replacePlaceholder(output, '{equipment}', capitalize(threshold))
      output = this.$replacePlaceholder(output, '{speed}', `${threshold} km/h`)
      output = this.$replacePlaceholder(output, '{points}', threshold)
      return this.$replacePlaceholder(output, /{value}|%s|{2to4}|{cash}/gi, target)
    },
  },
})
</script>

<style lang="scss" scoped>
.show-enter-from {
  opacity: 0;
}

.show-enter-to {
  opacity: 1;
}

.show-enter-active,
.show-leave-active {
  transition: all 0.7s ease;
}

.show-leave-from {
  opacity: 1;
}

.show-leave-to {
  opacity: 0;
}

.session-message {
  width: 65.625rem;
  height: 13rem;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0.313rem 0.813rem 0 #272727;
  padding-top: 0.3rem;

  &-theme-motive {
    position: absolute;
    width: 18.875rem;
    height: 13rem;
    background-image: url($path-components + 'sessionMessages/BG_ICON_LEFT.avif');
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
  }

  &-img {
    width: 15.5rem;
    height: 100%;
    position: relative;

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translate(-50%, -50%);
    }
  }

  &-content {
    width: 85%;
    text-align: left;
    padding-left: 5rem;
    padding-right: 1rem;

    .smsg-h2 {
      padding-left: 1rem;
    }

    .smsg-p1 {
      line-height: 2rem;
      padding-left: 1rem;
    }

    .smsg-background {
      background: rgba(11, 25, 42, 0.33);
      padding-left: 6rem;
      margin-left: -5.1rem;
      height: 4.45rem;
      margin-top: 0.5rem;
    }

    .smsg-p2 {
      display: flex;
      align-items: center;
    }

    .task-progress--margined {
      margin-left: 5.5rem;
    }

    &--event {
      padding-left: 0;
    }
  }

  $theme-bg-1: linear-gradient(45deg, #1d506e 0%, #0c304c 100%);
  $theme-bg-2: linear-gradient(45deg, #7e2d35 0%, #5d1219 100%);

  @if $isSsm {
    $theme-bg-1: linear-gradient(
      90deg,
      rgba(67, 71, 109, 1) 0%,
      rgba(81, 89, 122, 1) 60%,
      rgba(55, 67, 103, 1) 100%
    );
    $theme-bg-2: linear-gradient(
      90deg,
      rgba(157, 56, 44, 1) 0%,
      rgba(178, 56, 40, 1) 60%,
      rgba(132, 39, 33, 1) 100%
    );
  }

  $themes:
    1 $theme-bg-1 #dcbb2c transparent,
    2 $theme-bg-2 #a91a18 transparent;

  @if $isSsm {
    $themes:
      1 $theme-bg-1 #feb942 transparent,
      2 $theme-bg-2 #ff6344 transparent;
  }

  @each $theme, $bg, $border, $corner in $themes {
    &.theme-#{$theme} {
      background: $bg;
      border: solid 0.125rem $border;
      border-top: none;
      display: flex;

      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 1.375rem;
        height: 1.375rem;
        background: transparent;
        border-bottom: 0.125rem solid $corner;
        bottom: -0.125rem;
      }

      &:before {
        left: -0.125rem;
        border-left: 0.125rem solid $corner;
      }

      &:after {
        right: -0.1rem;
        border-right: 0.125rem solid $corner;
      }
    }
  }
}

.session-title {
  padding-left: 1rem;
}

[data-event-theme='challenge_cup'] {
  --session-message-event-logo: url('#{$path-events}challenge-cup/event-logo-base.avif');

  @if $isWsm {
    --session-message-event-circle: url('#{$path-events}shared/milestones/milestones-progress-active.avif');
  }

  @if $isSsm {
    --session-message-event-circle: url('#{$path-events}shared/milestones/milestones-progress-active-cc.avif');
  }
}

[data-event-theme='frozen'] {
  --session-message-event-logo: url('#{$path-events}frozen-championship/event-logo-base.avif');
  --session-message-event-circle: url('#{$path-events}shared/milestones/milestones-progress-active.avif');
}

[data-event-theme='discipline'] {
  --session-message-event-logo: url('#{$path-events}discipline-event/event-logo-base.webp');
  --session-message-event-circle: url('#{$path-events}shared/milestones/milestones-progress-active.avif');
}

.club-message-milestone {
  width: 72.3125rem;
  height: 24rem;
  background: url($path-events + 'milestones/event-milestone-complete-popup.avif') center no-repeat;
  background-size: cover;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .club-milestone {
    margin-left: 0;
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .cup {
    height: 7.25rem;
    width: 48.56rem;
    background: var(--session-message-event-logo) center no-repeat;
    background-size: contain;
  }

  .texts {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .top-box {
      background: url($path-events + 'milestones/box-title.avif') center no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      background-size: contain;
      width: 54.5rem;
      height: 6rem;
    }

    .rewards-section-milestone {
      @include background(var(--session-message-event-circle), contain);
      width: 10.125rem;
      height: 10.3125rem;
      margin-left: -5rem;

      &.is-repeatable {
        position: relative;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 3.625rem;
          height: 3.688rem;
          margin-top: 0.1875rem;
          margin-left: 0.125rem;
          @include background(url('#{$path-events}shared/milestones/ico-repeat.webp'), contain);
        }
      }
    }
  }
}
.game-pass-milestone {
  width: 65rem;
  height: 17.625rem;
  background: url($path-premium + 'gamePass/game-pass-session-message.avif') center no-repeat;
  background-size: cover;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  & > p {
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    span {
      margin-left: 1rem;
      padding-right: 1rem;
    }
  }
  .gradient-text-light {
    background-clip: text;
    text-shadow: none;
    color: transparent;

    @if $isWsm {
      background-image: linear-gradient(to top, #ffedbd 13%, #fffcf1 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #d7d7d7 13%, #ffffff 100%);
      filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.86));
    }
  }
}

.tournaments-milestone {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 65rem;
  height: 17.625rem;
  @include background(
    url('#{$path-images}/tournaments/session-message/milestone-session-message-bg.avif'),
    contain
  );
  z-index: 11;

  & > p {
    position: absolute;
    bottom: 2.125rem;
    left: 50%;
    transform: translateX(-50%);
    height: 5.875rem;

    span {
      position: absolute;
      bottom: 0.125rem;
      right: 3rem;
      width: 6rem;
      height: 5.5rem;

      &.is-repeatable {
        bottom: 1.125rem;
        right: 4.125rem;
        width: 3.625rem;
        height: 3.688rem;
        @include background(url('#{$path-images}/tournaments/milestones/ico-repeat.avif'), contain);
      }
    }
  }

  .gradient-text-light {
    background-clip: text;
    text-shadow: none;
    color: transparent;

    @if $isWsm {
      background-image: linear-gradient(to top, #ffedbd 13%, #fffcf1 100%);
    }

    @if $isSsm {
      background-image: linear-gradient(to top, #d7d7d7 13%, #ffffff 100%);
      filter: drop-shadow(0 0 0.25rem rgba(0, 0, 0, 0.86));
    }
  }
}

.piggy-bank-milestone {
  display: flex;
  flex-direction: row;
  width: 65.625rem;
  height: 11.188rem;
  background-image: linear-gradient(to top, #43476d, #51597a, #374367);
  border: solid 0.125rem #feb942;
  border-top: none;
  background-size: cover;
  z-index: 11;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  .session-message-theme-motive {
    position: relative;
    height: 100%;
    background-image: url($path-components + 'sessionMessages/BG_ICON_LEFT.avif');
    background-size: contain;
    background-repeat: no-repeat;
    left: 0;
  }

  &__img {
    position: relative;
    height: 100%;
    pointer-events: none;
  }

  &__content {
    position: relative;
    flex: 1 1 auto;
  }

  .piggy-bank-icon {
    position: relative;
    width: 34.938rem;
    height: 3.938rem;
    background: url($path-premium + 'piggy-bank/logo-piggy-bank.avif') center no-repeat;
    background-size: contain;
    margin: 1.25rem auto;
  }
}

.tournament-session-message {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65rem;
  height: 17.625rem;
  z-index: 11;

  &.is-tournament-start {
    @include background(
      url('#{$path-images}/tournaments/session-message/tournament-start-bg.avif'),
      contain
    );
  }

  &.is-tournament-end {
    @include background(
      url('#{$path-images}/tournaments/session-message/tournament-end-bg.avif'),
      contain
    );
  }

  &-icon {
    position: absolute;
    bottom: 0.25rem;
    left: 1.5rem;
    width: 12.063rem;
    height: 16.188rem;
    @include background(url('#{$path-images}/tournaments/session-message/cup.avif'), contain);
    z-index: 2;
  }

  &-title {
    width: 63.75rem;
    height: 6.5rem;

    border-style: solid;
    border-width: 0.25rem;
    border-image-source: linear-gradient(
      to right,
      transparent 0%,
      rgba(249, 186, 39, 0.9) 50%,
      transparent 100%
    );
    border-image-slice: 1;
    z-index: 0;

    &.is-tournament-start {
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(1, 83, 73, 0.9) 25%,
          rgba(1, 83, 73, 0.9) 75%,
          transparent 100%
        );
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba($color: #126314e6, $alpha: 0.9) 25%,
          rgba($color: #126314e6, $alpha: 0.9) 75%,
          transparent 100%
        );
      }
    }

    &.is-tournament-end {
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba(132, 30, 17, 0.9) 25%,
          rgba(132, 30, 17, 0.9) 75%,
          transparent 100%
        );
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent 0%,
          rgba($color: #840b0be6, $alpha: 0.9) 25%,
          rgba($color: #840b0be6, $alpha: 0.9) 75%,
          transparent 100%
        );
      }
    }

    * {
      z-index: 1;
    }

    .discipline-icon {
      margin-left: 13.5rem;
    }
  }

  &-text {
    width: 63.75rem;

    p {
      margin-left: 5rem;
    }
  }
}
</style>
