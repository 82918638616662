<template>
  <div data-notifications-handler />
</template>

<script lang="ts">
import { MECHANIC_TOURNAMENTS } from '@/globalVariables'
import { SET_BUBBLE_NOTIFICATIONS, TOURNAMENTS } from '@/map-phaser-new/constants'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MapNotificationsHandler',
  computed: {
    ...mapState(useResponseTaskStore, {
      hasMechanic: 'hasMechanic',
      notifications: 'getNotifications',
    }),
    ...mapState(usePhaserGameIntegrationStore, { phaserEventEmitter: 'getPhaserEventEmitter' }),
  },
  watch: {
    'notifications.tournaments': {
      handler(): void {
        if (!this.hasMechanic(MECHANIC_TOURNAMENTS)) return
        this.phaserEventEmitter.emit(SET_BUBBLE_NOTIFICATIONS, TOURNAMENTS)
      },
      deep: true,
    },
  },
})
</script>
