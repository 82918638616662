import {
  eventLeagueConfigEndpoint,
  eventLeagueContributorsEndpoint,
  eventLeagueStateEndpoint,
  eventTotalRankingsEndpoint,
} from '@/globalVariables'
import { internalAxios } from '@/plugins/vueAxios'

import type {
  CommodityPoints,
  LeagueConfigApiResponse,
} from '@/interfaces/responses/events/league/LeagueConfigApiResponse'
import type { LeagueContributorsApiResponse } from '@/interfaces/responses/events/league/LeagueContributorsApiResponse'
import type { LeagueStateApiResponse } from '@/interfaces/responses/events/league/LeagueStateApiResponse'
import type { LeagueTotalRankingsApiResponse } from '@/interfaces/responses/events/league/LeagueTotalRankingsApiResponse'
import { defineStore } from 'pinia'

export interface LeagueState {
  config: LeagueConfigApiResponse | null
  state: LeagueStateApiResponse | null
  contributors: LeagueContributorsApiResponse | null
  totalRankings: LeagueTotalRankingsApiResponse | null
}

export const useLeagueEventStore = defineStore('useLeague', {
  state: (): LeagueState => ({
    config: null,
    state: null,
    contributors: null,
    totalRankings: null,
  }),
  getters: {
    getLeagueId(): LeagueState['config']['leagues'][0]['league_id'] | null {
      return this.config?.leagues[0].league_id ?? null
    },

    getConfig(): LeagueState['config']['leagues'][0] | null {
      return this.config?.leagues[0] ?? null
    },

    getState(): LeagueState['state'] | null {
      return this.state ?? null
    },

    getTotalRankings(): LeagueState['totalRankings'] | null {
      return this.totalRankings ?? null
    },

    getRoundRemainingTime(): LeagueState['state']['time_left'] | null {
      return this.state?.time_left ?? null
    },

    getLeagueContributors(): LeagueState['contributors']['contributions'] | null {
      return this.contributors?.contributions ?? null
    },

    getMinimumClubMembers():
      | LeagueState['config']['leagues'][0]['minimal_club_members_count']
      | null {
      return this.config?.leagues[0].minimal_club_members_count ?? null
    },

    getIsRecalculating(): LeagueState['state']['is_recalculating'] | null {
      return this.state?.is_recalculating ?? null
    },

    getIsLeagueEnded(): boolean | null {
      if (this.state?.activity === 2) {
        return true
      } else {
        return null
      }
    },

    getCommodityPointsConfig(): CommodityPoints | null {
      const points = this.config?.leagues[0].commodity_points ?? null
      if (points === null || Array.isArray(points)) return null
      return this.config?.leagues[0].commodity_points as CommodityPoints
    },
  },

  actions: {
    async loadConfig(force: boolean = false): Promise<void> {
      if (this.config !== null && !force) return

      try {
        const data = await internalAxios.post<{}, LeagueConfigApiResponse>(
          eventLeagueConfigEndpoint,
        )

        if (data == null) return

        this.config = data
      } catch (error: unknown) {
        console.error(error)
      }
    },

    async loadState(round?: number): Promise<void> {
      try {
        const data = await internalAxios.post<{}, LeagueStateApiResponse>(
          eventLeagueStateEndpoint,
          {
            league_id: this.getLeagueId,
            round,
          },
        )

        if (data == null) return

        this.state = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadContributors(): Promise<void> {
      try {
        const data = await internalAxios.get<{}, LeagueContributorsApiResponse>(
          eventLeagueContributorsEndpoint,
        )

        if (data === null) return

        this.contributors = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
    async loadTotalRankings(): Promise<void> {
      try {
        const data = await internalAxios.post<{}, LeagueTotalRankingsApiResponse>(
          eventTotalRankingsEndpoint,
          {
            league_id: this.getLeagueId,
          },
        )

        if (data == null) return

        this.totalRankings = data
      } catch (error: unknown) {
        console.error(error)
      }
    },
  },
})
