// TODO: Ked bude vsetko mergnute, tak tento subor premenovat z languages na translations.
// TODO: Treba vycistit univerzalne kluce z eventov a odkomentovat overenie na konci suboru.

export const eventTranslations = {
  challenge_cup: {
    /* doplnene */
    eventpass: 'event.eventpass',
    championpass: 'event.championpass',
    back: 'button.back',
    yourReward: 'event.yourReward',
    clubContributors: 'event.clubContributors',
    eventStarted: 'event.eventStarting',
    free: 'event.free',
    freeUsageAction: 'event.freeUsageAction',
    freeUsagePerDay: 'event.freeUsagePerDay',
    freeUsageExpiration: 'event.freeUsageExpiration',
    actualTasksForPlayoff: 'event.actualTasksForPlayoff',

    sessionModalTasksDoneText: 'event.seriesCompleted',
    tasksDoneTitle: 'event.tasksDoneAllTasksCongrat',
    tasksDoneText: 'event.tasksDoneAllTasks',

    eventPassPerk1Title: 'event.eventPassTraining',
    eventPassPerk1Text: 'event.eventPassTrainingLess',
    eventPassPerk2Title: 'event.eventPassStarts',
    eventPassPerk2Text: 'event.eventPassBackStarts',
    eventPassPerk3Title: 'event.eventPassWheel',
    eventPassPerk3Text: 'event.eventPassSpinFree',

    /* povodne */
    eventInformation: 'event.information',
    eventTaskNumber: 'event.taskNumber',
    eventBoss: 'event.boss',
    noActiveEventTasks: 'event.noActiveEventTasks',
    eventTasks: 'event.eventsTasks',
    commonRewards: 'common.rewards',
    commonTakeRewards: 'common.takeRewards',
    commonPrice: 'common.price',
    commonName: 'common.name',
    commonClaim: 'common.claim',
    commonReward: 'common.reward',
    eventEnded: 'event.eventEnded',
    eventRecalculation: 'event.recalculation',
    nextRoundStartsIn: 'event.nextRoundStartsIn',
    playoffStartsAt: 'event.playoffStartsAt',
    playoffQualificationOngoing: 'event.playoffQualificationOngoing',
    playoffEliminated: 'event.playoffEliminated',
    notQualified: 'event.notQualified',
    eventStart: 'event.eventStart',
    eventEnd: 'event.eventEnd',
    buttonContinue: 'button.continue',
    buttonStart: 'button.start',
    buttonPurchase: 'button.purchase',
    eventStarting: 'event.eventStarting',
    availableRewards: 'event.availableRewards',
    eventMechanics: 'event.eventMechanics',
    eventInfoMechanic1: 'event.eventInfoMechanic1',
    eventInfoMechanic2: 'event.eventInfoMechanic2',
    eventInfoMechanic3: 'event.eventInfoMechanic3',
    eventInfoMechanic4: 'event.eventInfoMechanic4',
    summary: 'event.summary',
    eventSummaryPlayoff: 'event.eventSummaryPlayoff',
    eventSummaryTasks: 'event.eventSummaryTasks',
    eventSummaryMilestones: 'event.eventSummaryMilestones',
    eventSummaryEventPass: 'event.eventSummaryEventPass',
    eventSummaryChampionPass: 'event.eventSummaryChampionPass',
    eventSummaryOffers: 'event.eventSummaryOffers',
    eventSummaryLuckyWheel: 'event.eventSummaryLuckyWheel',
    eventsTasks: 'event.eventsTasks',
    tier: 'event.tier',
    taskNumber: 'event.taskNumber',
    skip: 'event.skip',
    actualTaskFinish: 'event.actualTaskFinish',
    confirmActualTaskFinish: 'event.confirmActualTaskFinish',
    finishNow: 'event.finishNow',
    moreEnergy: 'event.moreEnergy',
    moreStarts: 'event.moreStarts',
    infoTaskchain: 'event.infoTaskchain',
    aboutTaskchain: 'event.aboutTaskchain',
    bossFightTask: 'event.bossFightTask',
    rightTeamInfo: 'event.redTeamInfo',
    rightTeam: 'event.redTeam',
    leftTeamInfo: 'event.blueTeamInfo',
    leftTeam: 'event.blueTeam',
    choose: 'event.choose',
    taskchainTaskSerie: 'event.taskchainTaskSerie',
    confirmChain: 'event.confirmChain',
    chainCompletionWarning: 'event.chainCompletionWarning',
    doneTasksAllTiersOneTeam: 'event.doneTasksAllTiersOneTeam',
    doneAllTasks: 'event.doneAllTasks',
    doneAllLeftTasks: 'event.doneAllBlueTasks',
    doneAllRightTasks: 'event.doneAllRedTasks',
    doneAllTeamTaskTier2: 'event.doneAllTeamTaskTier2',
    chooseTeam: 'event.chooseTeam',
    playoffRules: 'event.playoffRules',
    qualification: 'event.qualification',
    playoff: 'event.playoff',
    positionShort: 'event.positionShort',
    medals: 'event.medals',
    league: 'event.league',
    round: 'event.round',
    playoffQualificationEnded: 'event.playoffQualificationEnded',
    reward: 'event.reward',
    score: 'event.score',
    playoffRewardsNotAvailable: 'event.playoffRewardsNotAvailable',
    playoffWinner: 'event.playoffWinner',
    playoffEnded: 'event.playoffEnded',
    playoffDrawRound2: 'event.playoffDrawRound2',
    playoffDrawRound3: 'event.playoffDrawRound3',
    playoffDrawRound4: 'event.playoffDrawRound4',
    bossFightYouWon: 'event.bossFightYouWon',
    bossFightYouLost: 'event.bossFightYouLost',
    continue: 'event.continue',
    bossFightPlayAgain: 'event.bossFightPlayAgain',
    playoffRulesOne: 'event.playoffRulesOne',
    playoffRulesTwo: 'event.playoffRulesTwo',
    playoffRulesThree: 'event.playoffRulesThree',
    playoffRulesFour: 'event.playoffRulesFour',
    playoffRulesFive: 'event.playoffRulesFive',
    playoffRulesSix: 'event.playoffRulesSix',
    playoffOne: 'event.playoffOne',
    playoffTwo: 'event.playoffTwo',
    playoffThree: 'event.playoffThree',
    tabTaskchain: 'event.tabTaskchain',
    tabMilestones: 'event.tabMilestones',
    tabPlayoff: 'event.tabPlayoff',
    tabLuckyWheel: 'event.tabLuckyWheel',
    tabPass: 'event.tabPass',
    tabOffers: 'event.tabOffers',
    eventPassDurationTooltip: 'event.eventPassDurationTooltip',
    eventPassActive: 'event.eventPassActive',
    championPassInfo: 'event.championPassInfo',
    clubMilestone: 'event.clubMilestone',
    yourMilestone: 'event.yourMilestone',
    allMilestoneDone: 'event.allMilestoneDone',
    nextMilestone: 'event.nextMilestone',
    eventPassInfo: 'event.eventPassInfo',
    eventPassTrainingLess: 'event.eventPassTrainingLess',
    eventPassTraining: 'event.eventPassTraining',
    eventPassStarts: 'event.eventPassStarts',
    eventPassBackStarts: 'event.eventPassBackStarts',
    eventPassWheel: 'event.eventPassWheel',
    eventPassSpinFree: 'event.eventPassSpinFree',
    aboutMilestone: 'event.aboutMilestone',
    aboutMilestonesMain: 'event.clubMemeberPoints',
    aboutMilOne: 'event.aboutMilOne',
    aboutMilTwo: 'event.aboutMilTwo',
    aboutMilThree: 'event.aboutMilThree',
    aboutMilFour: 'event.aboutMilFour',
    aboutMilFive: 'event.aboutMilFive',
    aboutMilSix: 'event.aboutMilSix',
    aboutMilSeven: 'event.aboutMilSeven',
    basicRewards: 'event.basicRewards',
    eventPoints: 'event.eventPoints',
    eventPointsRequired: 'event.eventPointsRequired',
    unclaimableRewardEventEnded: 'event.unclaimableRewardEventEnded',
    clubRewards: 'event.clubRewards',
    getClub: 'event.getClub',
    clubs: 'event.clubs',
    sumRewards: 'event.sumRewards',
    taskEndofEvent: 'event.taskEndofEvent',
    commonRewardsClaimed: 'common.rewardsClaimed',
    onlyForClubMilestone: 'event.onlyForClubMilestone',
    commonMainCareerTaskRequired: 'common.mainCareerTaskRequired',
    freeSpinCount: 'event.freeSpinCount',
    infoSpinChance: 'event.infoSpinChance',
    infoSpinChanceAvailability: 'event.infoSpinChanceAvailability',
    infoSpinMultiplier: 'event.infoSpinMultiplier',
    spinInfo: 'event.spinInfo',
    spin: 'event.spin',
  },
  frozen: {
    /** COPY-PASTE -> brat z default **/
    aboutMilestone: 'event.aboutMilestone',
    mastersDescription: 'event.mastersDescription',
    aboutMilFive: 'event.aboutMilFive',
    aboutMilFour: 'event.aboutMilFour',
    aboutMilOne: 'event.aboutMilOne',
    aboutMilSix: 'event.aboutMilSix',
    aboutMilSeven: 'event.aboutMilSeven',
    aboutMilThree: 'event.aboutMilThree',
    aboutMilTwo: 'event.aboutMilTwo',
    aboutTaskchain: 'event.aboutTaskchain',
    actualTaskFinish: 'event.actualTaskFinish',
    allMilestoneDone: 'event.allMilestoneDone',
    availableRewards: 'event.availableRewards',
    basicRewards: 'event.basicRewards',
    bossFightPlayAgain: 'event.bossFightPlayAgain',
    bossFightTask: 'event.bossFightTask',
    bossFightYouLost: 'event.bossFightYouLost',
    bossFightYouWon: 'event.bossFightYouWon',
    buttonContinue: 'button.continue',
    buttonPurchase: 'button.purchase',
    buttonStart: 'button.start',
    chainCompletionWarning: 'event.chainCompletionWarning',
    championPassInfo: 'event.championPassInfo',
    choose: 'event.choose',
    chooseTeam: 'event.chooseTeam',
    // aboutMilestonesMain: 'event.clubMemeberPoints',
    clubMilestone: 'event.clubMilestone',
    clubRewards: 'event.clubRewards',
    clubs: 'event.clubs',
    // eventStarting: 'event.eventStarting',
    commonClaim: 'common.claim',
    commonMainCareerTaskRequired: 'common.mainCareerTaskRequired',
    commonName: 'common.name',
    commonPrice: 'common.price',
    commonReward: 'common.reward',
    commonRewards: 'common.rewards',
    commonRewardsClaimed: 'common.rewardsClaimed',
    commonTakeRewards: 'common.takeRewards',
    confirmActualTaskFinish: 'event.confirmActualTaskFinish',
    confirmChain: 'event.confirmChain',
    continue: 'event.continue',
    doneAllLeftTasks: 'event.doneAllBlueTasks',
    doneAllRightTasks: 'event.doneAllRedTasks',
    doneAllTasks: 'event.doneAllTasks',
    doneAllTeamTaskTier2: 'event.doneAllTeamTaskTier2',
    doneTasksAllTiersOneTeam: 'event.doneTasksAllTiersOneTeam',
    eventBoss: 'event.boss',
    eventEnd: 'event.eventEnd',
    eventEnded: 'event.eventEnded',
    // eventInfoMechanic1: 'event.eventInfoMechanic1',
    // eventInfoMechanic2: 'event.eventInfoMechanic2',
    // eventInfoMechanic3: 'event.eventInfoMechanic3',
    // eventInfoMechanic4: 'event.eventInfoMechanic4',
    eventInformation: 'event.information',
    eventTaskNumber: 'event.taskNumber',
    noActiveEventTasks: 'event.noActiveEventTasks',
    eventTasks: 'event.eventsTasks',
    eventRecalculation: 'event.recalculation',
    nextRoundStartsIn: 'event.nextRoundStartsIn',
    playoffStartsAt: 'event.playoffStartsAt',
    playoffQualificationOngoing: 'event.playoffQualificationOngoing',
    playoffEliminated: 'event.playoffEliminated',
    notQualified: 'event.notQualified',
    eventStart: 'event.eventStart',
    eventMechanics: 'event.eventMechanics',
    summary: 'event.summary',
    eventSummaryPlayoff: 'event.eventSummaryPlayoff',
    eventSummaryTasks: 'event.eventSummaryTasks',
    eventSummaryMilestones: 'event.eventSummaryMilestones',
    eventSummaryEventPass: 'event.eventSummaryEventPass',
    eventSummaryChampionPass: 'event.eventSummaryChampionPass',
    eventSummaryOffers: 'event.eventSummaryOffers',
    eventSummaryLuckyWheel: 'event.eventSummaryLuckyWheel',
    eventsTasks: 'event.eventsTasks',
    tier: 'event.tier',
    taskNumber: 'event.taskNumber',
    skip: 'event.skip',
    finishNow: 'event.finishNow',
    moreEnergy: 'event.moreEnergy',
    moreStarts: 'event.moreStarts',
    infoTaskchain: 'event.infoTaskchain',
    rightTeamInfo: 'event.redTeamInfo',
    leftTeamInfo: 'event.blueTeamInfo',
    taskchainTaskSerie: 'event.taskchainTaskSerie',
    playoffRules: 'event.playoffRules',
    qualification: 'event.qualification',
    playoff: 'event.playoff',
    positionShort: 'event.positionShort',
    medals: 'event.medals',
    league: 'event.league',
    round: 'event.round',
    playoffQualificationEnded: 'event.playoffQualificationEnded',
    reward: 'event.reward',
    score: 'event.score',
    playoffRewardsNotAvailable: 'event.playoffRewardsNotAvailable',
    playoffWinner: 'event.playoffWinner',
    playoffEnded: 'event.playoffEnded',
    playoffDrawRound1: 'event.playoffDrawRound1',
    playoffDrawRound2: 'event.playoffDrawRound2',
    playoffDrawRound3: 'event.playoffDrawRound3',
    playoffDrawRound4: 'event.playoffDrawRound4',
    playoffRulesOne: 'event.playoffRulesOne',
    playoffRulesTwo: 'event.playoffRulesTwo',
    playoffRulesThree: 'event.playoffRulesThree',
    playoffRulesFour: 'event.playoffRulesFour',
    playoffRulesFive: 'event.playoffRulesFive',
    playoffRulesSix: 'event.playoffRulesSix',
    playoffOne: 'event.playoffOne',
    playoffTwo: 'event.playoffTwo',
    playoffThree: 'event.playoffThree',
    tabTaskchain: 'event.tabTaskchain',
    tabMilestones: 'event.tabMilestones',
    // tabPlayoff: 'event.tournaments',
    tabLuckyWheel: 'event.tabLuckyWheel',
    tabPass: 'event.tabPass',
    tabOffers: 'event.tabOffers',
    eventPassDurationTooltip: 'event.eventPassDurationTooltip',
    eventPassActive: 'event.eventPassActive',
    yourMilestone: 'event.yourMilestone',
    nextMilestone: 'event.nextMilestone',
    eventPassInfo: 'event.eventPassInfo',
    eventPassTrainingLess: 'event.eventPassTrainingLess',
    eventPassTraining: 'event.eventPassTraining',
    eventPassStarts: 'event.eventPassStarts',
    eventPassBackStarts: 'event.eventPassBackStarts',
    eventPassWheel: 'event.eventPassWheel',
    eventPassSpinFree: 'event.eventPassSpinFree',
    eventPoints: 'event.eventPoints',
    eventPointsRequired: 'event.eventPointsRequired',
    unclaimableRewardEventEnded: 'event.unclaimableRewardEventEnded',
    getClub: 'event.getClub',
    sumRewards: 'event.sumRewards',
    taskEndofEvent: 'event.taskEndofEvent',
    onlyForClubMilestone: 'event.onlyForClubMilestone',
    freeSpinCount: 'event.freeSpinCount',
    infoSpinChance: 'event.infoSpinChance',
    infoSpinChanceAvailability: 'event.infoSpinChanceAvailability',
    infoSpinMultiplier: 'event.infoSpinMultiplier',
    spinInfo: 'event.spinInfo',
    spin: 'event.spin',
    tournaments: 'event.tournaments',
    tournamentRanking: 'reports.btnRanking',
    detail: 'button.detail',
    start: 'event.start',
    signUp: 'event.signUp',
    qualificationRules1: 'event.qualificationRules1',
    qualificationRules2: 'event.qualificationRules2',
    playoffRules1: 'event.playoffRules1',
    mastersRules1: 'event.mastersRules1',
    mastersRules2: 'event.mastersRules2',
    mastersRules3: 'event.mastersRules3',
    frozenMasters: 'event.frozenMasters',
    mastersPoints: 'event.mastersPoints',
    topPlayersQualifyMessage: 'event.topPlayersQualifyMessage',
    playoffTask1: 'event.playoffTask1',
    playoffTask2: 'event.playoffTask2',
    playoffTask3: 'event.playoffTask3',
    playoffTask4: 'event.playoffTask4',
    playoffTask6: 'event.playoffTask6',
    playoffTask7: 'event.playoffTask7',
    playoffTaskGainRankingPointsDiscipline: 'event.playoffTaskGainRankingPointsDiscipline',
    playoffTaskGainTrainingPointsDiscipline: 'event.playoffTaskGainTrainingPointsDiscipline',
    playoffTaskWinMatchesDiscipline: 'event.playoffTaskWinMatchesDiscipline',
    playoffTaskSpendMostEnergy: 'event.playoffTaskSpendMostEnergy',
    playoffTaskGainMostRankingPoints: 'event.playoffTaskGainMostRankingPoints',
    playoffTaskGainMostTrainingStarsMiniGame: 'event.playoffTaskGainMostTrainingStarsMiniGame',
    playoffTaskGainMostClubChampionShipPoints: 'event.playoffTaskGainMostClubChampionShipPoints',
    playoffTaskGainMostTrainingPointsMiniGame: 'event.playoffTaskGainMostTrainingPointsMiniGame',
    playoffTaskWinMostCompetitions: 'event.playoffTaskWinMostCompetitions',
    tournamentTraining1: 'event.tournamentTraining',
    tournamentTraining2: 'event.tournamentTraining',
    tournamentCompetitions1: 'event.tournamentCompetitions',
    tournamentCompetitions2: 'event.tournamentCompetitions',
    tournament100Meters: 'event.tournament100Meters',
    tournamentArchery: 'event.tournamentArchery',
    tournamentTrap: 'event.tournamentTrap',
    tournamentTrackCycling: 'event.tournamentTrackCycling',
    tournamentKayak: 'event.tournamentKayak',
    tournamentJavelin: 'event.tournamentJavelin',
    tournamentDownhill: 'event.tournamentDownhill',
    tournamentBiathlon: 'event.tournamentBiathlon',
    tournamentSkiJumping: 'event.tournamentSkiJumping',
    tournamentTraining: 'event.tournamentTraining',
    tournamentCompetitions: 'event.tournamentCompetitions',
    tournamentBobsleigh: 'event.tournamentBobsleigh',
    tournamentSpeedSkating: 'event.tournamentSpeedSkating',
    tournamentGiantSlalom: 'event.tournamentGiantSlalom',
    tournamentCrossCountrySkiing: 'event.tournamentCrossCountrySkiing',
    tournamentLuge: 'event.tournamentLuge',
    tournamentSnowBoardCross: 'event.tournamentSnowBoardCross',
    tournamentShortTrack: 'event.tournamentShortTrack',
    signUpPrompt: 'event.signUpPrompt',
    signedUp: 'event.signedUp',
    position: 'event.position',
    notSignedUp: 'event.notSignedUp',
    rankingNotAvailable: 'event.rankingNotAvailable',
    dataUpdateTime: 'common.dataUpdateTime',
    playoffEliminatedInRound1: 'event.playoffEliminatedInRound1',
    playoffEliminatedInRound2: 'event.playoffEliminatedInRound2',
    playoffEliminatedInRound3: 'event.playoffEliminatedInRound3',
    actualTasksForPlayoff: 'event.actualTasksForPlayoff',
    playoffPlayoffOngoing: 'event.playoffPlayoffOngoing',
    inQuarterfinal: 'event.inQuarterfinal',
    inSemifinal: 'event.inSemifinal',
    inFinal: 'event.inFinal',
    quarterfinalInProgress: 'event.quarterfinalInProgress',
    semifinalInProgress: 'event.semifinalInProgress',
    finalInProgress: 'event.finalInProgress',
    frozenChampionships: 'event.FrozenChampionships',
    frozenLab: 'event.FrozenLab',
    workshop: 'event.workshop',
    frozenLeague: 'event.frozenLeague',
    partsPackage: 'event.partsPackage',
    getPartsPackage: 'event.getPartsPackage',
    partsPackageConfirmMessage: 'event.partsPackageConfirmMessage',
    price: 'event.price',
    youNeed: 'event.youNeed',
    build: 'event.build',
    buyButton: 'event.buyButton',
    missingParts: 'event.missingParts',
    frozenInfoText1: 'event.frozenInfoText1',
    frozenInfoText2: 'event.frozenInfoText2',
    frozenInfoText3: 'event.frozenInfoText3',
    frozenInfoText4: 'event.frozenInfoText4',
    frozenInfoText5: 'event.frozenInfoText5',
    frozenInfoText6: 'event.frozenInfoText6',
    frozenInfoText7: 'event.frozenInfoText7',
    frozenInfoText8: 'event.frozenInfoText8',
    frozenInfoText9: 'event.frozenInfoText9',
    frozenInfoText10: 'event.frozenInfoText10',
    frozenInfoText11: 'event.frozenInfoText11',
    infoBadgeTooltip: 'event.infoBadgeTooltip',
    freePacksReady: 'event.freePacksReady',
    // wsm
    ingredientSnowFlake: 'event.ingredientSnowFlake',
    ingredientSnowflake: 'event.ingredientSnowFlake',
    ingredientIcePick: 'event.ingredientIcePick',
    ingredientIceCube: 'event.ingredientIceCube',
    ingredientGlacier: 'event.ingredientGlacier',
    ingredientComet: 'event.ingredientComet',
    ingredientElixir: 'event.ingredientElixir',
    ingredientWinterEssence: 'event.ingredientWinterEssence',
    buildFrostyCocktail: 'event.buildFrostyCocktail',
    buildIceSculpture: 'event.buildIceSculpture',
    buildComfortableIgloo: 'event.buildComfortableIgloo',
    buildEpicSnowShovel: 'event.buildEpicSnowShovel',
    buildSingingSnowman: 'event.buildSingingSnowman',
    frostEnergy: 'event.frostEnergy',
    // ssm
    ingredientWood: 'event.ingredientWood',
    ingredientBronze: 'event.ingredientBronze',
    ingredientCrystal: 'event.ingredientCrystal',
    ingredientSilver: 'event.ingredientSilver',
    ingredientGold: 'event.ingredientGold',
    ingredientDiamond: 'event.ingredientDiamond',
    ingredientGrandTokens: 'event.ingredientGrandTokens',
    buildLocalTrophy: 'event.buildLocalTrophy',
    buildNationalCup: 'event.buildNationalCup',
    buildCrystalBowl: 'event.buildCrystalBowl',
    buildGoldenAward: 'event.buildGoldenAward',
    buildGrandMastersCup: 'event.buildGrandMastersCup',
    triumphPoints: 'event.triumphPoints',

    /** SHARED **/
    eventpass: 'event.eventpass',
    championpass: 'event.championpass',
    back: 'button.back',
    yourReward: 'event.yourReward',
    // eventInformation: 'event.information',
    clubContributors: 'event.clubContributors',

    /** FROZEN CHAMPIONSHIP / HEADER **/
    tabPlayoff: 'event.tournaments',

    /** FROZEN CHAMPIONSHIP / TEASER **/
    eventStarting: 'event.comingFrozenEvent',
    eventStarted: 'event.FrozenEventStarted',
    eventInfoMechanic1: 'event.FrozenEventInfoMechanic1',
    eventInfoMechanic2: 'event.FrozenEventInfoMechanic2',
    eventInfoMechanic3: 'event.FrozenEventInfoMechanic3',
    eventInfoMechanic4: 'event.FrozenEventInfoMechanic4',
    eventSummaryFrozenMastersPlayoff: 'event.FrozenEventSummaryMastersPlayoff',
    eventSummaryFrozenLeague: 'event.FrozenEventSummaryLeague',

    /** FROZEN CHAMPIONSHIP / TASKCHAIN / CHAINS **/
    chainsHeaderText: 'event.FrozenStoryIntro',
    chainsSubHeaderText: 'event.chooseStoryPrompt',
    chainsPopupTitle: 'event.stories',
    chainsPopupText: 'event.storiesInfo',
    // chain1Title: 'event.FrozenStory1', // image
    // chain2Title: 'event.FrozenStory2', // image
    // chain3Title: 'event.FrozenStory3', // image
    chain1Tooltip: 'event.FrozenStoryInfo1',
    chain2Tooltip: 'event.FrozenStoryInfo2',
    chain3Tooltip: 'event.FrozenStoryInfo3',

    /**  FROZEN CHAMPIONSHIP / TASKCHAIN / TEAMS **/
    leftTeam: 'event.frozenCharacter1',
    // leftTeamInfo: 'event.redTeamInfo', // TODO: remove from CC
    rightTeam: 'event.frozenCharacter2',
    // rightTeamInfo: 'event.redTeamInfo', // TODO: remove from CC

    /**  FROZEN CHAMPIONSHIP / TASKCHAIN / TASKS **/
    sessionModalTasksDoneText: 'event.seriesCompleted',
    tasksDoneTitle: 'event.tasksDoneAllTasksCongrat',
    tasksDoneText: 'event.tasksDoneAllTasks',

    /** FROZEN CHAMPIONSHIP / MILESTONES */
    aboutMilestonesMain: 'event.aboutMilestonesMainFrozen',

    /**  FROZEN CHAMPIONSHIP / PASSES **/
    eventPassInfo1: 'event.eventPassInfo1',
    eventPassInfo2: 'event.eventPassInfo2',
    eventPassInfo3: 'event.eventPassInfo3',
    purchased: 'event.purchased',
    activated: 'event.activated',
    details: 'event.details',
    eventPassPerk1A: 'event.eventPassPerk1A',
    eventPassPerk1B: 'event.eventPassPerk1B',
    eventPassPerk2A: 'event.eventPassPerk2',
    eventPassPerk2B: 'event.eventPassPerk2',
    eventPassPerk3A: 'event.eventPassPerk3A',
    eventPassPerk3B: 'event.eventPassPerk3B',
    eventPassPerk4A: 'event.eventPassPerk4',
    eventPassPerk4B: 'event.eventPassPerk4',
    free: 'event.free',
    freeUsageAction: 'event.freeUsageAction',
    freeUsagePerDay: 'event.freeUsagePerDay',
    freeUsageExpiration: 'event.freeUsageExpiration',

    // CONVERTOR
    workshopInfoText: 'event.workshopInfoText',
    melting: 'event.melting',
    production: 'event.production',
    productionInProgress: 'event.productionInProgress',
    productionCompleted: 'event.productionCompleted',
    produceButton: 'event.produceButton',
    meltButton: 'event.meltButton',
    partMelting: 'event.partMelting',
    partMeltingConfirm: 'event.partMeltingConfirm',
    partMeltingInfo: 'event.partMeltingInfo',
    dontShowMessage: 'event.dontShowMessage',
    partProduction: 'event.partProduction',
    partProductionConfirm: 'event.partProductionConfirm',
    productionTime: 'event.productionTime',
    instant: 'event.instant',
    warningEventEnd: 'event.warningEventEnd',
    speedUpConfirm: 'event.speedUpConfirm',

    // LEAGUE
    clubLeagueLockedInfo: 'event.clubLeagueLockedInfo',
    joinOrCreateClub: 'event.joinOrCreateClub',
    clubLeagueInfo: 'event.clubLeagueInfo',
    memberCount: 'event.memberCount',
    nextRoundOK: 'event.nextRoundOK',
    nextRoundIn: 'event.nextRoundIn',
    leagueEnded: 'event.leagueEnded',
    topLeagueAchieved: 'event.topLeagueAchieved',
    clubLeagueInstruction: 'event.clubLeagueInstruction',
    timeLeftRound: 'event.timeLeftRound',
    updateInProgress: 'event.updateInProgress',
  },
  discipline: {
    // common
    reputation: 'event.reputation', // Toto nema `discipline` namespace kvoli BE, aby to bolo univerzalne pre rewardy.
    reputationRequired: 'event.reputationRequired',

    // about event
    aboutHeader: 'event.discipline.about.header',
    aboutText1: 'event.discipline.about.text1',
    aboutText2: 'event.discipline.about.text2',
    aboutText3: 'event.discipline.about.text3',
    aboutText4: 'event.discipline.about.text4',
    aboutText5: 'event.discipline.about.text5',
    aboutText6: 'event.discipline.about.text6',

    // teaser
    // TODO: prefix with `teaser.` after WSM-7656 merge
    eventStarting: 'event.discipline.teaser.eventStarting',
    eventStartingNew: 'event.discipline.teaser.eventStartingNew',
    eventStarted: 'event.discipline.teaser.eventStarted',
    eventInfoMechanic1: 'event.discipline.teaser.eventInfoMechanic1',
    eventInfoMechanic2: 'event.discipline.teaser.eventInfoMechanic2',
    eventInfoMechanic3: 'event.discipline.teaser.eventInfoMechanic3',
    eventInfoMechanic4: '', // TODO: v default mat vsetky ako empty string

    // overview
    eventDisciplineFocus: 'event.discipline.overview.disciplineFocus',
    eventOverviewInfo: 'event.discipline.overview.info',
    eventIncreaseReputation: 'event.discipline.overview.increaseReputation',
    eventYourReputation: 'event.discipline.overview.yourReputation',
    eventReputation: 'event.discipline.overview.reputation',
    eventDescription: 'event.discipline.overview.description',
    eventCompetition: 'event.discipline.overview.competition',
    eventTraining: 'event.discipline.overview.training',
    eventMoreInfo: 'event.discipline.overview.moreInfo',
    reputationInfoTooltip: 'event.discipline.overview.reputationInfo',

    // discipline menu
    'disciplineMenu.event': 'event.discipline.disciplineMenu.event',

    // training
    'training.headerText': 'event.discipline.training.headerText',

    // master pass
    'masterPass.perksTitle': 'event.discipline.masterPass.perksTitle',
    'masterPass.perk1Text': 'event.discipline.masterPass.perk1Text',
    'masterPass.perk2Text': 'event.discipline.masterPass.perk2Text',

    // rankings
    rewardsInfoTotal: 'event.discipline.rankings.rewardsInfoTotal',
    rewardsInfoRound: 'event.discipline.rankings.rewardsInfoRound',
    totalRankings: 'event.discipline.rankings.total',
    roundRankings: 'event.discipline.rankings.round',
    totalStandings: 'event.discipline.rankings.totalStandings',
    totalStandingsRewards: 'event.discipline.rankings.totalStandingsRewards',

    // milestones
    'milestones.earlyAccess': 'event.discipline.milestones.earlyAccess',
    'milestones.earlyAccessMilestoneText': 'event.discipline.milestones.earlyAccessMilestoneText',
    'milestones.repeatableMilestoneTitle': 'event.discipline.milestones.repeatableMilestoneTitle',
    'milestones.repeatableMilestoneText': 'event.discipline.milestones.repeatableMilestoneText',
  },
  default: {
    /* doplnene */
    eventpass: 'event.eventpass',
    championpass: 'event.championpass',
    masterpass: 'event.masterpass',
    back: 'button.back',
    yourReward: 'event.yourReward',
    clubContributors: 'event.clubContributors',
    eventStarted: 'event.eventStarting',
    free: 'event.free',
    freeUsageAction: 'event.freeUsageAction',
    freeUsagePerDay: 'event.freeUsagePerDay',
    freeUsageExpiration: 'event.freeUsageExpiration',
    actualTasksForPlayoff: 'event.actualTasksForPlayoff',
    updateInProgress: 'event.updateInProgress',

    sessionModalTasksDoneText: 'event.seriesCompleted',
    tasksDoneTitle: 'event.tasksDoneAllTasksCongrat',
    tasksDoneText: 'event.tasksDoneAllTasks',

    /* povodne */
    eventInformation: 'event.information',
    eventTaskNumber: 'event.taskNumber',
    eventBoss: 'event.boss',
    noActiveEventTasks: 'event.noActiveEventTasks',
    eventTasks: 'event.eventsTasks',
    commonRewards: 'common.rewards',
    commonTakeRewards: 'common.takeRewards',
    commonPrice: 'common.price',
    commonName: 'common.name',
    commonClaim: 'common.claim',
    commonReward: 'common.reward',
    eventEnded: 'event.eventEnded',
    eventRecalculation: 'event.recalculation',
    nextRoundStartsIn: 'event.nextRoundStartsIn',
    playoffStartsAt: 'event.playoffStartsAt',
    playoffQualificationOngoing: 'event.playoffQualificationOngoing',
    playoffEliminated: 'event.playoffEliminated',
    notQualified: 'event.notQualified',
    eventStart: 'event.eventStart',
    eventEnd: 'event.eventEnd',
    buttonContinue: 'button.continue',
    buttonStart: 'button.start',
    buttonPurchase: 'button.purchase',
    eventStarting: 'event.eventStarting',
    availableRewards: 'event.availableRewards',
    eventMechanics: 'event.eventMechanics',
    eventInfoMechanic1: 'event.eventInfoMechanic1',
    eventInfoMechanic2: 'event.eventInfoMechanic2',
    eventInfoMechanic3: 'event.eventInfoMechanic3',
    // eventInfoMechanic4: 'event.eventInfoMechanic4',
    eventInfoMechanic4: '',
    summary: 'event.summary',
    eventSummaryPlayoff: 'event.eventSummaryPlayoff',
    eventSummaryTasks: 'event.eventSummaryTasks',
    eventSummaryMilestones: 'event.eventSummaryMilestones',
    eventSummaryEventPass: 'event.eventSummaryEventPass',
    eventSummaryChampionPass: 'event.eventSummaryChampionPass',
    eventSummaryOffers: 'event.eventSummaryOffers',
    eventSummaryLuckyWheel: 'event.eventSummaryLuckyWheel',
    eventsTasks: 'event.eventsTasks',
    tier: 'event.tier',
    taskNumber: 'event.taskNumber',
    skip: 'event.skip',
    actualTaskFinish: 'event.actualTaskFinish',
    confirmActualTaskFinish: 'event.confirmActualTaskFinish',
    finishNow: 'event.finishNow',
    moreEnergy: 'event.moreEnergy',
    moreStarts: 'event.moreStarts',
    infoTaskchain: 'event.infoTaskchain',
    aboutTaskchain: 'event.aboutTaskchain',
    bossFightTask: 'event.bossFightTask',
    rightTeamInfo: 'event.redTeamInfo',
    rightTeam: 'event.redTeam',
    leftTeamInfo: 'event.blueTeamInfo',
    leftTeam: 'event.blueTeam',
    choose: 'event.choose',
    taskchainTaskSerie: 'event.taskchainTaskSerie',
    confirmChain: 'event.confirmChain',
    chainCompletionWarning: 'event.chainCompletionWarning',
    doneTasksAllTiersOneTeam: 'event.doneTasksAllTiersOneTeam',
    doneAllTasks: 'event.doneAllTasks',
    doneAllLeftTasks: 'event.doneAllBlueTasks',
    doneAllRightTasks: 'event.doneAllRedTasks',
    doneAllTeamTaskTier2: 'event.doneAllTeamTaskTier2',
    chooseTeam: 'event.chooseTeam',
    playoffRules: 'event.playoffRules',
    qualification: 'event.qualification',
    playoff: 'event.playoff',
    positionShort: 'event.positionShort',
    medals: 'event.medals',
    league: 'event.league',
    round: 'event.round',
    playoffQualificationEnded: 'event.playoffQualificationEnded',
    reward: 'event.reward',
    score: 'event.score',
    playoffRewardsNotAvailable: 'event.playoffRewardsNotAvailable',
    playoffWinner: 'event.playoffWinner',
    playoffEnded: 'event.playoffEnded',
    playoffDrawRound2: 'event.playoffDrawRound2',
    playoffDrawRound3: 'event.playoffDrawRound3',
    playoffDrawRound4: 'event.playoffDrawRound4',
    bossFightYouWon: 'event.bossFightYouWon',
    bossFightYouLost: 'event.bossFightYouLost',
    continue: 'event.continue',
    bossFightPlayAgain: 'event.bossFightPlayAgain',
    playoffRulesOne: 'event.playoffRulesOne',
    playoffRulesTwo: 'event.playoffRulesTwo',
    playoffRulesThree: 'event.playoffRulesThree',
    playoffRulesFour: 'event.playoffRulesFour',
    playoffRulesFive: 'event.playoffRulesFive',
    playoffRulesSix: 'event.playoffRulesSix',
    playoffOne: 'event.playoffOne',
    playoffTwo: 'event.playoffTwo',
    playoffThree: 'event.playoffThree',
    tabTaskchain: 'event.tabTaskchain',
    tabMilestones: 'event.tabMilestones',
    tabPlayoff: 'event.tabPlayoff',
    tabLuckyWheel: 'event.tabLuckyWheel',
    tabPass: 'event.tabPass',
    tabOffers: 'event.tabOffers',
    tabOverview: 'event.tabOverview',
    tabTraining: 'event.tabTraining',
    tabArena: 'event.tabArena',
    tabRankings: 'event.tabRankings',
    tabMasterPass: 'event.tabMasterPass',
    eventPassDurationTooltip: 'event.eventPassDurationTooltip',
    eventPassActive: 'event.eventPassActive',
    championPassInfo: 'event.championPassInfo',
    clubMilestone: 'event.clubMilestone',
    yourMilestone: 'event.yourMilestone',
    allMilestoneDone: 'event.allMilestoneDone',
    nextMilestone: 'event.nextMilestone',
    eventPassInfo: 'event.eventPassInfo',
    eventPassTrainingLess: 'event.eventPassTrainingLess',
    eventPassTraining: 'event.eventPassTraining',
    eventPassStarts: 'event.eventPassStarts',
    eventPassBackStarts: 'event.eventPassBackStarts',
    eventPassWheel: 'event.eventPassWheel',
    eventPassSpinFree: 'event.eventPassSpinFree',
    aboutMilestone: 'event.aboutMilestone',
    aboutMilestonesMain: 'event.clubMemeberPoints',
    aboutMilOne: 'event.aboutMilOne',
    aboutMilTwo: 'event.aboutMilTwo',
    aboutMilThree: 'event.aboutMilThree',
    aboutMilFour: 'event.aboutMilFour',
    aboutMilFive: 'event.aboutMilFive',
    aboutMilSix: 'event.aboutMilSix',
    aboutMilSeven: 'event.aboutMilSeven',
    basicRewards: 'event.basicRewards',
    eventPoints: 'event.eventPoints',
    eventPointsRequired: 'event.eventPointsRequired',
    unclaimableRewardEventEnded: 'event.unclaimableRewardEventEnded',
    clubRewards: 'event.clubRewards',
    getClub: 'event.getClub',
    clubs: 'event.clubs',
    sumRewards: 'event.sumRewards',
    taskEndofEvent: 'event.taskEndofEvent',
    commonRewardsClaimed: 'common.rewardsClaimed',
    onlyForClubMilestone: 'event.onlyForClubMilestone',
    commonMainCareerTaskRequired: 'common.mainCareerTaskRequired',
    freeSpinCount: 'event.freeSpinCount',
    infoSpinChance: 'event.infoSpinChance',
    infoSpinChanceAvailability: 'event.infoSpinChanceAvailability',
    infoSpinMultiplier: 'event.infoSpinMultiplier',
    spinInfo: 'event.spinInfo',
    spin: 'event.spin',
    dontShowMessage: 'event.dontShowMessage',
  },
}
/*
if (import.meta.env.NODE_ENV !== 'production') {
  for (const event in eventTranslations) {
    for (const key in eventTranslations[event]) {
      if (!eventTranslations[event][key].startsWith('event.'))
        throw new Error(
          `Event translation key should have 'event' prefix: ${eventTranslations[event][key]}`
        )
    }
  }
}
*/
