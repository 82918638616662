<template>
  <section
    class="milestones-sub-header w-full flex items-center justify-between uppercase"
    :data-event-theme="namespace"
  >
    <p class="text-60 gradient-text-light gradient-text-last-letter-cut-helper font-bold relative">
      <span v-if="isClub">
        {{ $te('clubMilestone') }}
      </span>
      <span v-else>{{ $te('yourMilestone') }}</span>
      <span
        v-if="isClub && hasClub"
        class="club-contributors cursor-pointer absolute"
        @click="showContributorsPopup = true"
      />
    </p>
    <aside v-if="isMilestonesProgressbarVisible" class="flexing">
      <p class="text-36 text-texts-standard-default relative next-text">
        {{ $te('nextMilestone') }}
      </p>
      <div class="progress relative flex items-center text-texts-standard-default text-50">
        <div class="actual-mls flexing" :class="{ 'is-repeatable': isRepeatable }">
          <template v-if="!isRepeatable">{{ currentMilestone }}</template>
        </div>
        <div class="progress-wrapper flexing relative">
          <div
            class="progress-wrapper-fill absolute"
            :class="{ 'is-repeatable': isRepeatable }"
            :style="{
              width:
                calculateProgress(
                  currentMilestonePoints.points,
                  currentMilestonePoints.next_points,
                ) + '%',
            }"
          />
          <div class="progress-wrapper-label">
            <p class="progress-wrapper-label__points text-28 font-bold">
              {{
                `${$filters.$formatNumber(
                  currentMilestonePoints.points,
                )} / ${$filters.$formatNumber(currentMilestonePoints.next_points)}`
              }}
            </p>
            <app-main-icon
              v-if="[EventType.ChallengeCup, EventType.FrozenChampionship].includes(namespace)"
              class="ml-1 z-1"
              :icon-name="EVENT_POINTS"
              :icon-size="32"
            />
            <app-icon
              v-else-if="namespace === EventType.DisciplineEvent"
              :icon-name="EVENT_REPUTATION"
              :tooltip-text="$te('reputation')"
              class="icon-in-progress ml-1 z-1"
            />
          </div>
        </div>
        <div class="next-mls flexing" :class="{ 'is-repeatable': isRepeatable }">
          <template v-if="!isRepeatable">
            {{ currentMilestonePoints.next_milestone }}
          </template>
        </div>
      </div>
    </aside>
    <p
      v-else
      class="all-milestones-done-text text-texts-standard-important text-36"
      :class="$isMobile() ? 'pb-0' : 'pb-2'"
    >
      {{ $te('allMilestoneDone') }}
    </p>
  </section>
  <club-contribution-popup
    :is-visible="showContributorsPopup"
    @close="showContributorsPopup = false"
  />
</template>

<script lang="ts">
import { EVENT_POINTS, EVENT_REPUTATION } from '@/globalVariables'
import { useUserStore } from '@/store/pinia/userStore'
import { useMilestonesStore } from '@/store/pinia/events/milestonesStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ClubContributionPopup from '../EventsPopups/ClubContributionPopup.vue'
import { EventType } from '@/interfaces/events/EventInfo'
import type MilestonesStateApiResponse from '@/interfaces/responses/events/milestones/MilestonesStateApiResponse'

interface ComponentData {
  EventType: typeof EventType
  EVENT_POINTS: typeof EVENT_POINTS
  EVENT_REPUTATION: typeof EVENT_REPUTATION
  showContributorsPopup: boolean
}

export default defineComponent({
  name: 'MilestonesSubHeader',
  components: {
    ClubContributionPopup,
  },
  props: {
    isClub: {
      type: Boolean,
      default: false,
    },
    isRepeatable: {
      type: Boolean,
      default: null,
    },
  },
  data(): ComponentData {
    return {
      EventType,
      EVENT_POINTS,
      EVENT_REPUTATION,
      showContributorsPopup: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      hasClub: 'getHasClub',
    }),
    ...mapState(useMilestonesStore, {
      _currentMilestonePoints: 'getCurrentMilestonePoints',
      currentMilestone: 'getCurrentMilestoneNumber',
      individualTasks: 'getIndividualBranchTasks',
      clubTasks: 'getClubBranchTasks',
    }),
    ...mapState(useEventInfoStore, {
      namespace: 'getEventType',
    }),
    currentMilestonePoints(): MilestonesStateApiResponse['milestone'] {
      if (this.isClub && !this.hasClub)
        return { number: 0, points: 0, next_points: 200, next_milestone: 1 }
      return this._currentMilestonePoints
    },
    isMilestonesProgressbarVisible(): boolean {
      if (this.currentMilestone === 0) return true

      if (this.isRepeatable) return true

      if (this.isClub) return this.currentMilestone !== this.currentMilestonePoints.next_milestone

      return this.currentMilestone !== (this.isClub ? this.clubTasks : this.individualTasks)?.length
    },
  },
  methods: {
    calculateProgress(a: number, b: number): number {
      return (a / b) * 100
    },
  },
})
</script>

<style lang="scss" scoped>
[data-event-theme='challenge_cup'] {
  --milestones-sub-header-background: url('#{$path-events}challenge-cup/milestones/milestones-header.avif');

  @if $isWsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-v1.avif');
    --milestones-sub-header-circle: url('#{$path-events}shared/milestones/milestones-header-number.avif');
  }

  @if $isSsm {
    --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-v1-cc.avif');
    --milestones-sub-header-circle: url('#{$path-events}shared/milestones/milestones-header-number-cc.avif');
  }
}

[data-event-theme='frozen'] {
  --milestones-sub-header-background: url('#{$path-events}frozen-championship/milestones/milestones-header.avif');
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-v1.avif');
  --milestones-sub-header-circle: url('#{$path-events}shared/milestones/milestones-header-number.avif');
}

[data-event-theme='discipline'] {
  --milestones-sub-header-background: url('#{$path-events}discipline-event/milestones/milestones-sub-header.webp');
  --milestones-progress-bg: url('#{$path-events}shared/milestones/milestones-progress-wrapper-v1.avif');
  --milestones-sub-header-circle: url('#{$path-events}shared/milestones/milestones-header-number.avif');
}

.milestones-sub-header {
  @include background(var(--milestones-sub-header-background), cover);
  height: 6.5625rem;
  padding: 0 2.125rem;

  .next-text {
    margin: -0.3rem 1.375rem 0;
  }

  .club-contributors {
    top: 1rem;
    right: -7.5rem;
    @include background(url($path-events + 'event-club-contributors.avif'), contain);
    width: 5.75rem;
    height: 3.625rem;
  }

  .progress {
    margin: 0 2rem;

    &-wrapper {
      @include background(var(--milestones-progress-bg), contain, center, repeat);
      width: 29.375rem;
      height: 8.5625rem;
      top: -5px;

      p {
        z-index: 1;
      }

      &-fill {
        @include background(
          url('#{$path-events}shared/milestones/progress-bar-fill--glowless-v1.avif'),
          contain,
          right center,
          repeat
        );
        height: 1.875rem;
        left: 0;
        top: calc(50% + 0.2rem);
        transform: translateY(-50%);

        &:not(.is-repeatable) {
          -webkit-filter: drop-shadow(0px 0px 5px rgb(249 227 152));
          filter: drop-shadow(0px 0px 5px rgb(249 227 152));
        }

        &::after {
          content: '';
          position: absolute;
          right: -1.188rem;
          top: 0;
          @include background(
            url('#{$path-events}shared/milestones/progress-bar-fill-end--glowless-v1.avif'),
            contain,
            -1px center
          );
          height: 100%;
          width: 1.25rem;
        }
      }

      &-label {
        height: 100%;
        display: flex;
        align-items: center;
        padding-top: 0.35rem;

        &__points {
          text-shadow: 0.163rem 0.194rem 0.175rem rgb(0 0 0 / 72%);
          -webkit-text-stroke: 0.063rem rgba(85, 20, 7, 0.76);
        }
      }
    }

    .actual-mls,
    .next-mls {
      position: absolute;
      @include background(var(--milestones-sub-header-circle), contain);
      width: 8.6875rem;
      height: 8.375rem;
      font-weight: bold;
      z-index: 1;
      padding-right: 0.25rem;
      @if $isWsm {
        font-style: italic;
      }
    }

    .actual-mls {
      left: -4.1875rem;

      &.is-repeatable {
        background-image: url('#{$path-events}shared/milestones/progress-bar-fill-start--glowless.avif');
        width: 2.25rem;
        height: 8.5625rem;
        top: -5px;
        left: -2.25rem;
      }
    }

    .next-mls {
      right: -4.1875rem;

      &.is-repeatable {
        &::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0.75);
          width: 3.625rem;
          height: 3.688rem;
          margin-top: -0.125rem;
          margin-left: -0.125rem;
          @include background(url('#{$path-events}shared/milestones/ico-repeat.webp'), contain);
        }
      }
    }
  }
}

.all-milestones-done-text {
  width: 50%;
}
</style>
